<script setup lang="ts">
import { computed } from 'vue';

import ThreeSixtyPage from '@/360/ThreeSixtyPage.vue';
import useLocations from '@/composables/useLocations';
import { Dashboards, DashboardTopics } from '@/constants/dashboards';
import useDashboardTopicCountryInfo from '@/composables/useDashboardTopicCountryInfo';

import type { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import { threeSixtyMapDefinitionsByPage } from '@/360/MapMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import useFilters from '@/composables/useFilters';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import Routes from '@/constants/routes';
import { MapOperatorSelector, useMapOperatorSelector } from '@/components/map-operator-selector';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import { MapEvents } from '@/components/visual/map/MapEvents';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';
import {
  PeriodComparisonCurrentItem,
  PeriodComparisonGrid,
  PeriodComparisonPreviousItem,
} from '@/components/onx/period-comparisons';
import { getPeriodString } from '@/360/base-station/getPeriodString';
import OnxSharedBounds from '@/components/onx/charts/OnxSharedBounds/OnxSharedBounds.vue';

const { selectedMapOperator } = useMapOperatorSelector(Dashboards.ThreeSixty);
const { countryIso3, locationId } = useLocations();
const { data: dashboardTopicCountryInfoResponse, isSuccess } = useDashboardTopicCountryInfo(
  DashboardTopics.CONGESTION,
  countryIso3.value,
);

const mapBboxFilterStore = useMapBboxFilterStore();

const mapMetrics = threeSixtyMapDefinitionsByPage[Routes.ThreeSixtyBaseStationCellCongestion];
const metrics = chartMetricDefinitionsByPage[Routes.ThreeSixtyBaseStationCellCongestion] as Record<
  string,
  Array<ChartMetricDefinition | ChartMetricDefinition[]>
>;
const nonPeriodMetrics = metrics.nonPeriodByOperator;

const { aggregation } = useFilters();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const previousPeriod = computed(() => {
  if (!isSuccess.value || !dashboardTopicCountryInfoResponse.value) {
    return '';
  }

  return getPeriodString(
    dashboardTopicCountryInfoResponse.value.data.report_dates.previous_start_date,
    dashboardTopicCountryInfoResponse.value.data.report_dates.previous_end_date,
  );
});

const currentPeriod = computed(() => {
  if (!isSuccess.value || !dashboardTopicCountryInfoResponse.value) {
    return '';
  }

  return getPeriodString(
    dashboardTopicCountryInfoResponse.value.data.report_dates.current_start_date,
    dashboardTopicCountryInfoResponse.value.data.report_dates.current_end_date,
  );
});

const buildPreviousMetric = (metric: any) => ({
  ...metric,
  requestParams: { period_split: 0 },
  exportAttrs: {
    ...metric.exportAttrs,
    subtitle: previousPeriod,
  },
});

const buildCurrentMetric = (metric: any) => ({
  ...metric,
  requestParams: { period_split: 1 },
  exportAttrs: {
    ...metric.exportAttrs,
    subtitle: currentPeriod,
  },
});
</script>

<template>
  <ThreeSixtyPage class="onx-360-congestion">
    <div v-if="selectedMapOperator" class="standard-mb">
      <OnxSelectorBlock title="Map filters">
        <MapOperatorSelector />
      </OnxSelectorBlock>

      <OnxSwyftMap
        v-for="metric in mapMetrics"
        :key="`${locationId}_${metric.id}_${mapBboxFilterStore.resetKey}`"
        :map-endpoint="metric.endpoint"
        :title="metric.title"
        :network-operators="[selectedMapOperator]"
        :class="metric.chartContainerClass"
        :geohash-level="7"
        :location-id="locationId"
        @[MapEvents.NewBounds]="mapBboxFilterStore.handleNewBounds"
        enable-bbox-filtering
      />
    </div>

    <div class="header-legend">
      <VTooltip>
        <div class="header-legend__item header-legend__ran-congestion">RAN Congestion</div>
        <template #popper>High probability of congestion in the radio link</template>
      </VTooltip>
      <VTooltip>
        <div class="header-legend__item header-legend__non-ran-congestion">Non-RAN Congestion</div>
        <template #popper>High probability of congestion elsewhere in the network, e.g. backhaul</template>
      </VTooltip>
      <VTooltip>
        <div class="header-legend__item header-legend__at-risk">At Risk</div>
        <template #popper>Without investment, at risk of becoming congested within 12 months</template>
      </VTooltip>
      <VTooltip>
        <div class="header-legend__item header-legend__underutilized-cells">Underutilized Cells</div>
        <template #popper>Underused cells with opportunity for energy saving or monetization</template>
      </VTooltip>
      <VTooltip>
        <div class="header-legend__item header-legend__unclassified">Unclassified</div>
        <template #popper>Cells operating efficiently</template>
      </VTooltip>
    </div>

    <PeriodComparisonGrid :current-period="currentPeriod" :previous-period="previousPeriod">
      <template v-for="metric in metrics.periodByOperator">
        <template v-if="!Array.isArray(metric)">
          <OnxSharedBounds :key="`${metric.metricSubtype}_${metric.connectionCategory}`">
            <PeriodComparisonPreviousItem>
              <ChartManager
                :metric="buildPreviousMetric(metric)"
                :connection-category="metric.connectionCategory!"
                :connection-category-label="metric.connectionCategory"
                :location="locationId"
                :geohashes="[]"
                :aggregation="aggregation"
                :operators="selectedOperators"
                :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
              />
            </PeriodComparisonPreviousItem>
            <PeriodComparisonCurrentItem>
              <ChartManager
                :metric="buildCurrentMetric(metric)"
                :connection-category="metric.connectionCategory!"
                :connection-category-label="metric.connectionCategory"
                :location="locationId"
                :geohashes="[]"
                :aggregation="aggregation"
                :operators="selectedOperators"
                :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
              />
            </PeriodComparisonCurrentItem>
          </OnxSharedBounds>
        </template>
      </template>
      <template v-for="metric in metrics.periodForEachOperator">
        <template v-if="!Array.isArray(metric)">
          <OnxSharedBounds :key="`${metric.metricSubtype}_${metric.connectionCategory}`">
            <template
              v-for="operator in selectedOperators"
              :key="`${metric.metricSubtype}_${metric.connectionCategory}_${operator.canonical_network_id}`"
            >
              <PeriodComparisonPreviousItem>
                <ChartManager
                  :metric="buildPreviousMetric(metric)"
                  :connection-category="metric.connectionCategory!"
                  :connection-category-label="metric.connectionCategory"
                  :location="locationId"
                  :geohashes="[]"
                  :aggregation="aggregation"
                  :operators="selectedOperators"
                  :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
                  :main-operator="operator"
                />
              </PeriodComparisonPreviousItem>
              <PeriodComparisonCurrentItem>
                <ChartManager
                  :metric="buildCurrentMetric(metric)"
                  :connection-category="metric.connectionCategory!"
                  :connection-category-label="metric.connectionCategory"
                  :location="locationId"
                  :geohashes="[]"
                  :aggregation="aggregation"
                  :operators="selectedOperators"
                  :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
                  :main-operator="operator"
                />
              </PeriodComparisonCurrentItem>
            </template>
          </OnxSharedBounds>
        </template>
        <template v-else>
          <template
            v-for="operator in selectedOperators"
            :key="`${metric.map((m) => m.metricSubtype).join('-')}_${operator.canonical_network_id}`"
          >
            <PeriodComparisonPreviousItem>
              <div class="gauge-row">
                <ChartManager
                  v-for="m in metric"
                  :key="`${m.metricSubtype}_${m.connectionCategory}_${operator.canonical_network_id}`"
                  :metric="buildPreviousMetric(m)"
                  :connection-category="m.connectionCategory!"
                  :connection-category-label="m.connectionCategory"
                  :location="locationId"
                  :geohashes="[]"
                  :aggregation="aggregation"
                  :operators="selectedOperators"
                  :main-operator="operator"
                  :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
                />
              </div>
            </PeriodComparisonPreviousItem>
            <PeriodComparisonCurrentItem>
              <div class="gauge-row">
                <ChartManager
                  v-for="m in metric"
                  :key="`${m.metricSubtype}_${m.connectionCategory}_${operator.canonical_network_id}`"
                  :metric="buildCurrentMetric(m)"
                  :connection-category="m.connectionCategory!"
                  :connection-category-label="m.connectionCategory"
                  :location="locationId"
                  :geohashes="[]"
                  :aggregation="aggregation"
                  :operators="selectedOperators"
                  :main-operator="operator"
                  :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
                />
              </div>
            </PeriodComparisonCurrentItem>
          </template>
        </template>
      </template>
    </PeriodComparisonGrid>

    <div>
      <template v-for="metric in nonPeriodMetrics">
        <div
          class="standard-mb"
          v-if="!Array.isArray(metric)"
          :key="`${metric.metricSubtype}_${metric.connectionCategory}`"
        >
          <ChartManager
            :metric="metric"
            :connection-category="metric.connectionCategory!"
            :connection-category-label="metric.connectionCategory"
            :location="locationId"
            :geohashes="[]"
            :aggregation="aggregation"
            :operators="selectedOperators"
            :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
          />
        </div>
      </template>
    </div>
  </ThreeSixtyPage>
</template>

<style lang="scss">
@use 'scss/mixins';
@import 'scss/onx-breakpoints.module';

.onx-360-congestion {
  .header-legend {
    display: flex;
    gap: 4px;
    margin-bottom: 8px;

    @include tablet() {
      margin-bottom: 16px;
    }

    &__item {
      display: inline-flex;
      align-items: center;
      cursor: help;

      text-transform: uppercase;
      height: 32px;
      padding: 4px 8px;

      color: white;
      font-size: mixins.pxToRem(12);
      line-height: mixins.pxToRem(12);
    }

    &__ran-congestion {
      background: rgba(134, 34, 117, 1);
    }

    &__non-ran-congestion {
      background: rgba(83, 182, 176, 1);
    }

    &__at-risk {
      background: rgba(243, 178, 62, 1);
    }
    &__underutilized-cells {
      background: rgba(235, 78, 99, 1);
    }
    &__unclassified {
      background: rgba(210, 212, 212, 1);
      color: rgba(121, 125, 127, 1);
    }
  }

  .gauge-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    gap: 8px;
    margin-bottom: 8px;

    @include tablet() {
      margin-bottom: 16px;
    }
  }

  .gauge-chart-cell {
    min-width: 250px;
    flex: 1;
    align-self: stretch;

    & > .onx-chart-container {
      min-height: 200px;
    }
  }
}
</style>
