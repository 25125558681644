import { MetricSubtypes } from '@/types/MetricSubtypes';

const labelsByMetricSubtype = {
  [MetricSubtypes.Download]: 'Download',
  [MetricSubtypes.PeakDownload]: 'Peak Download',
  [MetricSubtypes.ConsistencyDownload]: 'Consistency Download',
  [MetricSubtypes.Upload]: 'Upload',
  [MetricSubtypes.PeakUpload]: 'Peak Upload',
  [MetricSubtypes.ConsistencyUpload]: 'Consistency Upload',
  [MetricSubtypes.GamesExperience]: 'Games Experience',
  [MetricSubtypes.GamesJitter]: 'Games Jitter',
  [MetricSubtypes.GamesPacketDelay]: 'Games Packet Delay',
  [MetricSubtypes.GamesPacketLoss]: 'Games Packet Loss',
  [MetricSubtypes.Ecq]: 'ECQ',
  [MetricSubtypes.EcqDownloadThroughput]: 'ECQ Download Throughput',
  [MetricSubtypes.EcqUploadThroughput]: 'ECQ Upload Throughput',
  [MetricSubtypes.EcqIcmpLatency]: 'ECQ ICMP Latency',
  [MetricSubtypes.EcqJitter]: 'ECQ Jitter',
  [MetricSubtypes.EcqPacketLoss]: 'ECQ Packet Loss',
  [MetricSubtypes.EcqLatency]: 'ECQ Latency',
  [MetricSubtypes.EcqDownloadTimeFirstByte]: 'ECQ Download Time First Byte',
  [MetricSubtypes.EcqDownloadThroughputTestFailure]: 'ECQ Download Throughput Test Failure',
  [MetricSubtypes.EcqServerResponseTestFailure]: 'ECQ Server Response Test Failure',
  [MetricSubtypes.Ccq]: 'CCQ',
  [MetricSubtypes.CcqDownloadThroughput]: 'CCQ Download Throughput',
  [MetricSubtypes.CcqUploadThroughput]: 'CCQ Upload Throughput',
  [MetricSubtypes.CcqIcmpLatency]: 'CCQ ICMP Latency',
  [MetricSubtypes.CcqJitter]: 'CCQ Jitter',
  [MetricSubtypes.CcqPacketLoss]: 'CCQ Packet Loss',
  [MetricSubtypes.CcqLatency]: 'CCQ Latency',
  [MetricSubtypes.CcqDownloadTimeFirstByte]: 'CCQ Download Time First Byte',
  [MetricSubtypes.CcqDownloadThroughputTestFailure]: 'CCQ Download Throughput Test Failure',
  [MetricSubtypes.CcqServerResponseTestFailure]: 'CCQ Server Response Test Failure',
  [MetricSubtypes.VideoExperience]: 'Video ABR Experience',
  [MetricSubtypes.VideoAbrTtff]: 'Video ABR Time to First Frame',
  [MetricSubtypes.VideoAbrStallingOccurrence]: 'Video ABR Stalling Occurrence',
  [MetricSubtypes.VideoAbrStallingTime]: 'Video ABR Stalling Time',
  [MetricSubtypes.VideoAbrThroughput]: 'Video ABR Throughput',
  [MetricSubtypes.VideoAbrResTime]: 'Video ABR Time on Resolution',
  [MetricSubtypes.VoiceExperience]: 'Voice Experience',
  [MetricSubtypes.VoicePacketDelay]: 'Voice Packet Delay',
  [MetricSubtypes.VoicePacketLoss]: 'Voice Packet Loss',
  [MetricSubtypes.LiveVideoExperience]: 'Live Video Experience',
  [MetricSubtypes.LiveVideoOffset]: 'Live Video Average Offset',
  [MetricSubtypes.LiveVideoStallingTime]: 'Live Video Stalling Time',
  [MetricSubtypes.LiveVideoStallingOccurrence]: 'Live Video Stalling Occurrence',
  [MetricSubtypes.LiveVideoTtff]: 'Live Video Time to First Frame',
  [MetricSubtypes.LiveVideoThroughput]: 'Live Video Throughput',
  [MetricSubtypes.LiveVideoResTime]: 'Live Video Time on Resolution',
  [MetricSubtypes.ReliabilityCompletion]: 'Completion',
  [MetricSubtypes.ReliabilityConnectionSuccess]: 'Connection Success',
  [MetricSubtypes.ReliabilityConnectivity]: 'Connectivity',
  [MetricSubtypes.ReliabilitySessionRelability]: 'Session Completion',
  [MetricSubtypes.ReliabilitySufficiency]: 'Sufficiency',
  [MetricSubtypes.ReliabilityScorePerformanceDriver]: 'Score Performance Drivers',
  [MetricSubtypes.HourlyDownload]: 'Hourly Download',
  [MetricSubtypes.HourlyUpload]: 'Hourly Upload',
};

const getLabelForMetricSubtype = (metricSubtype: MetricSubtypes) => {
  return labelsByMetricSubtype[metricSubtype as keyof typeof labelsByMetricSubtype];
};

export default getLabelForMetricSubtype;
