<script setup lang="ts">
import { computed, ref, unref } from 'vue';

import AlphaSortIcon from '../icons/AlphaSortIcon.vue';
import ChevronUpIcon from '../icons/ChevronUpIcon.vue';
import FiltersIcon from '../icons/FiltersIcon.vue';
import OnxPulseAggregationDropdown from './OnxPulseAggregationDropdown.vue';
import OnxPulseChangeReasonDropdown from './OnxPulseChangeReasonDropdown.vue';
import OnxPulseDatePicker from './OnxPulseDatePicker.vue';
import OnxPulseItem from './OnxPulseItem.vue';
import OnxPulseMetricDropdown from './OnxPulseMetricDropdown.vue';
import OnxPulseOperatorDropdown from './OnxPulseOperatorDropdown.vue';
import OnxPulseReadStatusDropdown from './OnxPulseReadStatusDropdown.vue';
import OnxPulseSortControls from './OnxPulseSortControls.vue';
import useSpotlightPulse, { PulseSortColumn, SpotlightPulse, SpotlightPulseOptions } from './useSpotlightPulse';
import useLocations from '@/composables/useLocations';
import OnxTag from '@/components/onx/tags/OnxTag.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxPulseIcon from '@/components/onx/icons/OnxPulseIcon.vue';
import CloseIcon from '@/components/onx/icons/CloseIcon.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';

const maxNewItemsToShow = 100;

const { currentCountry } = useLocations();
const filters = ref<SpotlightPulseOptions>({
  sortColumn: 'date',
  aggregation: undefined,
  metric: undefined,
  operator: undefined,
  changeType: undefined,
  readStatus: undefined,
  generationDate: undefined,
});

const onClearFilters = () => {
  filters.value.aggregation = undefined;
  filters.value.metric = undefined;
  filters.value.operator = undefined;
  filters.value.changeType = undefined;
  filters.value.readStatus = undefined;
  filters.value.generationDate = undefined;
};

const onChangeSortColumn = (column: string) => {
  if (['change_type', 'date', 'operator'].includes(column)) {
    filters.value.sortColumn = column as PulseSortColumn;
  }
};

const filterCount = computed(() => {
  const filterKeys = ['aggregation', 'metric', 'operator', 'changeType', 'generationDate', 'readStatus'];
  return Object.entries(filters.value).filter(([key, value]) => {
    return filterKeys.includes(key) && value !== undefined;
  }).length;
});

const {
  allItems: allPulseItems,
  filteredAndSorted: pulseItemsAfterFilteringAndSorting,
  isFetching,
  isLoading,
  isRefetching,
  markMultiple,
  markOne,
  readItems,
} = useSpotlightPulse(currentCountry, filters);

/**
 * Whether we're currently marking all items as read
 */
const isMarkingAllRead = ref(false);

/**
 * Whether anything is loading or saving at the moment
 */
const anyLoading = computed(() => {
  return unref(isFetching) || unref(isLoading) || unref(isRefetching) || unref(isMarkingAllRead);
});

/**
 * Keeps track of items that the server thinks aren't viewed yet.
 * Note that this is still the list of pulse items belonging to this country, from useLocations,
 * but with no other filtering applied.
 */
const unreadItems = computed(() => {
  return pulseItemsAfterFilteringAndSorting.value.filter((item) => {
    return !readItems.value.has(item);
  });
});

/**
 * We don't want to notify the user that there's 7 billion or whatever new unread pulses.
 * We'll either display 0 to 99, or 99+. So this function limits the result to 100 and the UI
 * draws 99+ in that case.
 */
const notificationCount = computed(() => {
  return Math.min(100, unreadItems.value.length);
});

/**
 * Send an API request to mark these pulse items as read.
 */
const markAllRead = async () => {
  const itemsToMark = unreadItems.value;
  if (anyLoading.value || itemsToMark.length === 0) {
    return;
  }

  isMarkingAllRead.value = true;
  try {
    await markMultiple(itemsToMark, true);
  } finally {
    isMarkingAllRead.value = false;
  }
};

const markOneRead = async (item: SpotlightPulse) => {
  if (anyLoading.value || readItems.value.has(item)) {
    return;
  }

  return markOne(item, true);
};

const markOneUnread = async (item: SpotlightPulse) => {
  if (anyLoading.value || !readItems.value.has(item)) {
    return;
  }

  return markOne(item, false);
};

/** Shows Filters or Sorting options, or none if hidden */
const tab = ref<'filters' | 'sort'>();
const onChangeTab = (tabName: 'filters' | 'sort') => {
  tab.value = tabName;
};
const onCloseTab = () => {
  tab.value = undefined;
};
</script>

<template>
  <VDropdown :distance="6" placement="bottom-right" :autoHide="false">
    <div class="onx-spotlight-pulse__icon">
      <OnxPulseIcon />
      <OnxTag v-if="notificationCount > 0" class="onx-spotlight-pulse__notification-icon">
        {{ notificationCount > 99 ? '99+' : notificationCount }}
      </OnxTag>
    </div>
    <template #popper="{ hide }">
      <OnxPaper class="spotlight-pulse__container" :depth="3">
        <div class="spotlight-pulse__header">
          <div class="spotlight-pulse__header__icon">
            <OnxPulseIcon />
          </div>
          <div class="spotlight-pulse__header__text">
            <div class="spotlight-pulse__header__title">Spotlight Pulse</div>
            <div v-if="currentCountry.name" class="spotlight-pulse__header__country">
              {{ currentCountry.name }}
            </div>
          </div>
          <button class="spotlight-pulse__header__close">
            <CloseIcon @click="hide" />
          </button>
        </div>
        <div class="spotlight-pulse__controls">
          <template v-if="tab === 'filters'">
            <span class="spotlight-pulse__controls__tab active" @click="onCloseTab">
              <FiltersIcon />
              <span>Filters</span>
              <ChevronUpIcon className="spotlight-pulse__controls__tab__close" />
            </span>
            <span class="spotlight-pulse__controls__tab" @click="onChangeTab('sort')">
              <AlphaSortIcon />
              Sort
            </span>
          </template>
          <template v-else-if="tab === 'sort'">
            <span class="spotlight-pulse__controls__tab" @click="onChangeTab('filters')">
              <FiltersIcon />
              <span>Filters</span>
              <span v-if="filterCount > 0" class="spotlight-pulse__filter__chip" @click.stop="onClearFilters">
                {{ filterCount }}
                <CloseIcon />
              </span>
            </span>
            <span class="spotlight-pulse__controls__tab active" @click="onCloseTab">
              <AlphaSortIcon />
              Sort
              <ChevronUpIcon className="spotlight-pulse__controls__tab__close" />
            </span>
          </template>
          <template v-else>
            <span class="spotlight-pulse__controls__tab" @click="onChangeTab('filters')">
              <FiltersIcon />
              <span>Filters</span>
              <span v-if="filterCount > 0" class="spotlight-pulse__filter__chip" @click.stop="onClearFilters">
                {{ filterCount }}
                <CloseIcon />
              </span>
            </span>
            <span class="spotlight-pulse__controls__tab" @click="onChangeTab('sort')">
              <AlphaSortIcon />
              Sort
            </span>
          </template>
          <span class="spotlight-pulse__controls__expand"></span>
          <button
            v-if="tab !== undefined && filterCount > 0"
            class="spotlight-pulse__controls__mark-all-read"
            @click="onClearFilters"
          >
            Reset
          </button>
          <button
            v-else-if="unreadItems.length > 0 && pulseItemsAfterFilteringAndSorting.length > 0"
            class="spotlight-pulse__controls__mark-all-read"
            :class="{ is_marking_read: isMarkingAllRead }"
            @click.stop="markAllRead"
          >
            Mark all read
          </button>
        </div>
        <div class="spotlight-pulse__filters" v-if="tab === 'filters'">
          <OnxPulseAggregationDropdown
            :all-pulse-items="allPulseItems"
            :selected-aggregation="filters.aggregation"
            @change="(aggregation) => (filters.aggregation = aggregation)"
            @clear="() => (filters.aggregation = undefined)"
          />
          <OnxPulseMetricDropdown
            :all-pulse-items="allPulseItems"
            :selected-metric-subtype="filters.metric"
            @change="(metric) => (filters.metric = metric)"
            @clear="() => (filters.metric = undefined)"
          />
          <OnxPulseOperatorDropdown
            :all-pulse-items="allPulseItems"
            :selected-operator="filters.operator"
            @change="(operator) => (filters.operator = operator)"
            @clear="() => (filters.operator = undefined)"
          />
          <OnxPulseChangeReasonDropdown
            :all-pulse-items="allPulseItems"
            :selected-change-reason="filters.changeType"
            @change="(changeType) => (filters.changeType = changeType)"
            @clear="() => (filters.changeType = undefined)"
          />
          <OnxPulseReadStatusDropdown
            :all-pulse-items="allPulseItems"
            :selected-read-status="filters.readStatus"
            @change="(readStatus) => (filters.readStatus = readStatus)"
            @clear="() => (filters.readStatus = undefined)"
          />
          <OnxPulseDatePicker
            :all-pulse-items="allPulseItems"
            :selected-date="filters.generationDate"
            @change="(generationDate) => (filters.generationDate = generationDate)"
            @clear="() => (filters.generationDate = undefined)"
          />
        </div>
        <div class="spotlight-pulse__sort" v-if="tab === 'sort'">
          <OnxPulseSortControls :sortColumn="filters.sortColumn" @change-column="onChangeSortColumn" />
        </div>
        <div class="spotlight-pulse__items" :class="{ 'show-loading-grid': anyLoading }">
          <template v-if="pulseItemsAfterFilteringAndSorting.length > 0">
            <OnxPulseItem
              v-for="item in pulseItemsAfterFilteringAndSorting.slice(0, maxNewItemsToShow)"
              :key="JSON.stringify(item)"
              :item="item"
              :isNew="!readItems.has(item)"
              @mark-read="markOneRead(item)"
              @mark-unread="markOneUnread(item)"
              @navigate="hide"
            />
          </template>
          <div class="spotlight-pulse__no-items" v-else>No items</div>
          <LoaderGrid v-if="anyLoading" overlay="true" />
        </div>
      </OnxPaper>
    </template>
  </VDropdown>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.onx-spotlight-pulse__icon {
  height: 48px;
  width: 48px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--light);
  }

  .onx-tag {
    position: absolute;
    top: 6px;
    right: 2px;
    background-color: red;
  }
}

.spotlight-pulse__container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);

  @include tablet() {
    width: 640px;
  }
}

.spotlight-pulse__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--charcoal-100);
  height: 54px;
  padding-left: 1rem;
  height: 70px;

  @include tablet() {
    padding-left: 0;
    height: 70px;
  }

  &__icon {
    // Don't show the icon when the header is too narrow, to save space
    display: none;
    width: 3rem;
    height: 2rem;
    align-items: center;
    justify-content: center;

    @include tablet() {
      display: flex;
    }
  }

  &__text {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
  }

  &__title {
    font-weight: bold;

    // save space with a smaller header title on smaller screens
    font-size: 18px;
    @include tablet() {
      font-size: 24px;
    }
  }

  &__country {
    font-size: 12px;
    color: var(--charcoal-200);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    flex-shrink: 1;
    display: none;

    @include phablet() {
      display: block;
      position: relative;
      top: -0.5em;
    }
  }

  &__mark-all-read {
    white-space: nowrap;
    background: white;
    border: none;
    color: var(--charcoal-300);
    cursor: pointer;
    font-size: small;
    height: 2rem;
    line-height: 2rem;
    padding: 0 0.5rem;

    &.is_marking_read {
      color: var(--charcoal-200);
      cursor: not-allowed;
    }

    &:hover {
      background: var(--light);
    }

    &:active {
      background: var(--charcoal-100);
    }
  }

  &__close {
    align-items: center;
    background: white;
    border: none;
    color: var(--charcoal-300);
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    margin-right: 1rem;
    width: 2rem;

    &:hover {
      background: var(--light);
    }

    &:active {
      background: var(--charcoal-100);
    }
  }
}

.spotlight-pulse__controls {
  display: flex;
  align-items: end;
  padding-right: 0.5rem; // the last button will have its own spacing
  padding-left: 1rem;
  height: 3rem;
  font-size: small;

  &__tab {
    color: var(--charcoal-300);
    background-color: white;
    padding: 0.5rem 1rem 1rem;
    height: 2.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;

    &.active {
      border-radius: 4px 4px 0 0;
      background-color: var(--off-white);
      color: var(--teal-400);
    }

    &:hover {
      color: var(--teal-400);
    }

    .spotlight-pulse__filter__chip {
      background-color: var(--charcoal-500);
      color: white;
      border-radius: 1rem;
      padding: 0.25rem 0.5rem;
      font-size: x-small;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .onx-icon {
        width: 8px;
        height: 8px;
      }
    }
  }

  &__expand {
    flex-grow: 1;
  }

  &__mark-all-read {
    height: 2rem;
    cursor: pointer;
    margin-bottom: 0.5rem;

    white-space: nowrap;
    padding: 0 0.5rem;
    background: white;
    border: none;
    color: var(--charcoal-300);
    cursor: pointer;
    font-size: small;

    &.is_marking_read {
      color: var(--charcoal-200) !important;
      cursor: not-allowed;
    }

    &:hover {
      color: var(--teal-400);
      background: var(--light);
    }

    &:active {
      color: var(--charcoal-200);
      background: var(--charcoal-100);
    }
  }
}

.spotlight-pulse__filters,
.spotlight-pulse__sort {
  background-color: var(--off-white);
  display: flex;
  flex-direction: column;

  .onx-dropdown .onx-button {
    font-size: small;
    padding: 0 8px;
  }
}

.spotlight-pulse__items {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 550px;
  overflow-y: scroll;
  gap: 1px;
  border-top: 1px solid var(--charcoal-100);

  /* Bottom borders, but not the last item */
  > .spotlight-pulse__item {
    border-bottom: 1px solid var(--charcoal-100);

    &:last-child {
      border-bottom: none;
    }
  }

  /* Make sure there's enough room to show the loading icon even when there's no items yet */
  &.show-loading-grid {
    min-height: 300px;
  }
}

.spotlight-pulse__no-items {
  font-size: normal;
  color: var(--charcoal-200);
  padding: 1rem;
}
</style>
