import { computed, Ref } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useLocations from '@/composables/useLocations';

const useHomeNetwork = (dashboard: Dashboards, locationId: Ref<number | string | undefined>) => {
  const { data: response } = useDashboardInfo(dashboard);
  const { locationsByID } = useLocations();

  const homeNetwork = computed(() => {
    return response.value?.data.home_networks.find((network) => {
      if (!locationId.value) {
        return false;
      }

      return network.country_iso3 === locationsByID.value?.[locationId.value]?.iso3;
    });
  });

  return homeNetwork;
};

export default useHomeNetwork;
