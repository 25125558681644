import { computed, MaybeRef, unref } from 'vue';

import { type PolygonsWithMetricsType } from '@/composables/usePolygonsWithMetrics';
import { type Operator } from '@/types/Operator';

import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useAvailableGeocodings from '@/composables/useAvailableGeocodings';
import useSpotlightNetworkOperators from '@/spotlight/useSpotlightNetworkOperators';

import { GEO_POSITION, OS_GEOCODINGS } from '@/constants/constants';
import { getBucketColor } from '@/components/visual/map/helpers';
import { fixHexColor } from '@/utils/helpers';

const useRankedGeographies = (
  polygonsWithMetrics: MaybeRef<PolygonsWithMetricsType['value']>,
  homeNetwork: MaybeRef<Operator | undefined>,
  theme: MaybeRef<{ color: string; min: number; max: number; order: number }[]>,
) => {
  const queryParams = useSpotlightQueryParams({ geocoding: OS_GEOCODINGS.regions.toString() });
  const primaryMetric = useSpotlightPrimaryMetric();
  const { geocodingsWithLocations } = useAvailableGeocodings();
  const { isOperatorVisible } = useSpotlightNetworkOperators();

  const rankedGeographies = computed(() => {
    const _homeNetwork = unref(homeNetwork);

    const selectedGrouping = geocodingsWithLocations.value.find(
      (grouping: any) => grouping.id === parseInt(queryParams.geocoding.toValue(), 10),
    );
    const isSingleOperator = queryParams.network.toValue() !== 'all';

    let data = [];

    const focusedNetworkId =
      queryParams.network.toValue() !== 'all'
        ? parseInt(queryParams.network.toValue(), 10)
        : _homeNetwork?.canonical_network_id;
    if (selectedGrouping && selectedGrouping.id) {
      data = [...(unref(polygonsWithMetrics)?.features || [])];
      // filter the data based on selected operators by the user from the Compare Operators menu
      data = data.map((area: any) => {
        return {
          ...area,
          item: area.item.filter((item: any) => {
            if (isSingleOperator) {
              return item.canonical_network_id === focusedNetworkId;
            }

            return isOperatorVisible(item);
          }),
        };
      });

      if (isSingleOperator && data.length) {
        const _theme = unref(theme);
        data = data.map((area: any) => {
          return {
            ...area,
            item: area.item.map((item: any) => {
              return {
                ...item,
                color: getBucketColor(item.value, _theme),
              };
            }),
          };
        });
      }
    }

    data = data.map((area: any) => {
      return {
        ...area,
        operatorsById: area.item.reduce((acc: any, operator: any) => {
          acc[operator.canonical_network_id] = operator;
          return acc;
        }, {}),
      };
    });

    const rankedOnly: any[] = [];
    const allResults: any[] = [];

    data.forEach((dataItem: any) => {
      if (!dataItem.item.length) {
        return;
      }
      dataItem.homeArea = dataItem.item.find((item: any) => item.canonical_network_id === focusedNetworkId);

      rankedOnly.push(dataItem);
      allResults.push(dataItem);
    });

    const unsortedData = rankedOnly.length > 0 ? rankedOnly : allResults;

    const result = unsortedData.sort((a, b) => {
      if (!primaryMetric.value) {
        return 0;
      }

      const areaA = a.homeArea;
      const areaB = b.homeArea;
      if (!areaA) {
        return 1;
      }
      if (!areaB) {
        return -1;
      }
      if (areaA.rank == null && areaB.rank != null) {
        return 1;
      }
      if (areaB.rank == null && areaA.rank != null) {
        return -1;
      }
      if (primaryMetric.value.bigger_is_better) {
        return areaB.value - areaA.value;
      }
      return areaA.value - areaB.value;
    });

    // Divide between top, middle and bottom
    const divisionNumber = result.length / 3;
    const lastTopIndex = divisionNumber > 5 ? 4 : divisionNumber;
    const firstBottomIndex = divisionNumber > 5 ? result.length - 5 : result.length - (divisionNumber + 1);

    return result.map((area, index) => {
      area.index = index + 1;
      area.item = area.item.map((datum: any) => {
        datum.operatorName = datum.operator.name_mapped;
        datum.operatorInitial = datum.operator.name_mapped[0];
        datum.operatorColor = fixHexColor(datum.operator.hex_color);
        datum.operatorIsMvno = datum.operator.is_mvno;

        return datum;
      });

      if (index <= lastTopIndex) {
        area.position = GEO_POSITION.TOP;
        return area;
      }
      if (index >= firstBottomIndex) {
        area.position = GEO_POSITION.BOTTOM;
        return area;
      }

      return area;
    });
  });

  return rankedGeographies;
};

export default useRankedGeographies;
