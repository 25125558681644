<script setup lang="ts">
import { ref, computed, onMounted, watchEffect } from 'vue';
import { type LocationQuery, useRoute, useRouter } from 'vue-router';
import get from 'lodash/get';

import { type Operator } from '@/types/Operator';
import { type GeocodingConfig } from '@/types/GeocodingConfig';
import { MetricStructuresEnum, type RankedSimpleMetric } from '@/types/MetricStructures';

import OperatorAvatar from '@/components/OperatorAvatar.vue';
import SimpleDialog from '@/components/SimpleDialog.vue';
import CompetitiveTile from '@/components/comparison/CompetitiveTile.vue';
import SpotlightByGeographyLayout from '@/components/competitive/SpotlightByGeographyLayout.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import OnxCollapsible from '@/components/onx/OnxCollapsible.vue';
import { OnxList, OnxListItem, OnxListItemText } from '@/components/onx/OnxList';
import OnxMetricBox from '@/components/onx/OnxMetricBox.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import FocusPromotion from '@/components/onx/FocusPromotion.vue';
import useUser from '@/components/onx/composables/useUser';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';
import AlphaSortIcon from '@/components/onx/icons/AlphaSortIcon.vue';
import AwardIcon from '@/components/onx/icons/AwardIcon.vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import GridIcon from '@/components/onx/icons/GridIcon.vue';
import ListIcon from '@/components/onx/icons/ListIcon.vue';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon.vue';
import SortOrderIcon from '@/components/onx/icons/SortOrderIcon.vue';
import { OnxTab, OnxTabs, OnxTabsContext } from '@/components/onx/tabs';
import OnxCaption from '@/components/onx/typography/OnxCaption.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import OnxParagraph from '@/components/onx/typography/OnxParagraph.vue';
import { ChartWrapper, ChartWrapperExtended, ChoroplethMap, LineChart, RankingMap } from '@/components/visual';
import { metricMinAndMax } from '@/components/visual/map/helpers';
import {
  SPOTLIGHT_TO_FOCUS_ALLOWED,
  COPYRIGHT,
  OS_GEOCODINGS,
  TAB_NAMES_BY_GRANULARITY,
  CompareToValues,
} from '@/constants/constants';
import ROUTES from '@/constants/routes';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import { exportToCsv } from '@/utils/files';
import { customGroupingsFilter, osGroupingsFilter } from '@/utils/groupings';
import { isAwardMetric } from '@/utils/metrics';
import { getDatumValue, getRankLabel } from '@/utils/viewHelpers';
import { CustomTooltip } from '@/components/tooltip';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useLocations from '@/composables/useLocations';
import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useEndDate from '@/composables/useEndDate';
import OnxSpotlightBreadcrumbs from '@/components/onx/spotlight-header/OnxSpotlightBreadcrumbs.vue';
import useRankedGeographies from '@/spotlight/useRankedGeographies';
import usePolygonsWithMetrics from '@/composables/usePolygonsWithMetrics';
import useMetric from '@/composables/useMetric';
import useLocationOverview from '@/composables/useLocationOverview';
import getOrderedValues from '@/spotlight/getOrderedValues';
import getOperatorFilteredTrendSeries from '@/spotlight/getOperatorFilteredTrendSeries';
import createMetricBoxData from '@/spotlight/createMetricBoxData';
import getWinnersForRankingMap from '@/spotlight/getWinnersForRankingMap';
import { getCustomTitleLabels } from '@/utils/titles';
import { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';
import { fixHexColor } from '@/utils/helpers';
import { networksWithColors } from '@/utils/config';
import useMapPolygonColorScale from '@/composables/useMapPolygonColorScale';
import { dynamicScaleColors } from '@/constants/dynamicScaleColors';
import useTimeframeSettings from '@/components/timeframe-settings/useTimeframeSettings';
import useAvailableGeocodings from '@/composables/useAvailableGeocodings';
import useHomeNetwork from '@/composables/useHomeNetwork';
import useSpotlightNetworkOperators from '@/spotlight/useSpotlightNetworkOperators';
import { sortByMetricMeanAndRank } from '@/utils/data';
import useAnalytics from '@/composables/useAnalytics';
import { MetricDescriptor } from '@/types/MetricDescriptor';

const router = useRouter();
const route = useRoute();

const { track } = useAnalytics();

const {
  networkOperators: operators,
  selectedNetworkOperators: selectedOperators,
  setOperators,
} = useSpotlightNetworkOperators();

const displayNational = ref(false);
const isExpanded = ref(false);
const hoveredLocationData = ref<{ name: string; data: any }>();

type DisplayMode = 'grid' | 'list';
const displayMode = ref<DisplayMode>('grid');
const sortByRef = ref<string>();
const sortOrder = ref(-1);
const isOpenFocusPromotionDialog = ref(false);

const matches = useBreakpoints();
const { user } = useUser();
const currentMetric = useSpotlightPrimaryMetric();
const { currentEndDate } = useEndDate();
const { geocodingsWithLocations } = useAvailableGeocodings();

const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.Spotlight);
const lastDateAvailable = computed(() => dashboardInfoResponse.value?.data?.last_date_available);

const { countryIso3, currentCountry, currentLocation, locationId } = useLocations();
const currentCountryId = computed(() => parseInt(currentCountry.value.key, 10));

const homeNetwork = useHomeNetwork(Dashboards.Spotlight, locationId);

const queryParams = useSpotlightQueryParams({
  geocoding: OS_GEOCODINGS.regions.toString(),
  endDate: computed(() => currentEndDate.value.toISOString()),
});

const metricKey = computed(() => {
  return currentMetric.value?.key;
});

const metricKeyAvailable = computed(() => {
  return Boolean(metricKey.value);
});

const geocodingInt = computed(() => {
  return parseInt(queryParams.geocoding.toValue(), 10);
});

const { geocodingConfigQuery, polygonQueriesLoading, polygonsWithMetrics } =
  usePolygonsWithMetrics<MetricStructuresEnum.RankedSimple>(Dashboards.Spotlight, {
    metric: metricKey,
    geocoding: geocodingInt,
    aggregation: queryParams.agg.selectedValue,
    endDate: currentEndDate,
    countryIso3,
    operatorInfo: true,
    compareAllIntervals: true,
    enableGeocodingConfigQuery: metricKeyAvailable,
  });

const mapColorScaleData = computed(() => {
  return geocodingConfigQuery.data.value?.data.results || [];
});

const { colorScaleIntervals } = useMapPolygonColorScale(currentMetric, mapColorScaleData, dynamicScaleColors);

const rankedGeographies = useRankedGeographies(polygonsWithMetrics, homeNetwork, colorScaleIntervals);

// National stats
const { data: nationalStatsResponse, isLoading: isNationalDataLoading } =
  useLocationOverview<MetricStructuresEnum.RankedSimple>(Dashboards.Spotlight, {
    locationId: currentCountryId,
    metric: metricKey,
    aggregation: queryParams.agg.selectedValue,
    operatorInfo: true,
    endDate: currentEndDate,
    compareAllIntervals: true,
  });

const nationalStats = computed(() => {
  if (!nationalStatsResponse.value) {
    return [];
  }

  return nationalStatsResponse.value.data.results;
});

// Trend series
const trendSeriesEndDate = ref<Date>();
const trendSeriesNbDays = ref(365);

const onFetchOlderTrends = (nbDays: number, endDate: Date) => {
  trendSeriesNbDays.value = nbDays;
  trendSeriesEndDate.value = endDate;
};

const {
  query: { data: trendSeriesResponse, isLoading: isTrendSeriesLoading },
} = useMetric<MetricStructuresEnum.RankedSimple>(Dashboards.Spotlight, {
  metric: metricKey,
  location: locationId,
  aggregation: queryParams.agg.selectedValue,
  operatorInfo: true,
  nbDays: trendSeriesNbDays,
  endDate: trendSeriesEndDate,
});

const trendSeriesResponseResults = computed(() => {
  return trendSeriesResponse.value?.data.results || [];
});

const timeframeSettings = useTimeframeSettings({
  data: trendSeriesResponseResults,
  onSetTimeframe: (nbDays, hadCustomTimeRange) => {
    if (hadCustomTimeRange) {
      trendSeriesNbDays.value = 365;
      trendSeriesEndDate.value = undefined;
    }
  },
});

const filterSeries = (series: Parameters<typeof getOperatorFilteredTrendSeries>[0]) => {
  const filteredOperators =
    queryParams.network.toValue() === 'all'
      ? selectedOperators.value
      : operators.value.filter((o: Operator) => o.canonical_network_id === parseInt(queryParams.network.toValue(), 10));

  return getOperatorFilteredTrendSeries(series, timeframeSettings.timeRange.value, filteredOperators);
};

const trendSeries = computed(() => {
  return filterSeries(trendSeriesResponseResults.value);
});

// National trend series
const {
  query: { data: nationalTrendSeriesResponse },
} = useMetric<MetricStructuresEnum.RankedSimple>(Dashboards.Spotlight, {
  metric: metricKey,
  location: currentCountryId,
  aggregation: queryParams.agg.selectedValue,
  operatorInfo: true,
  nbDays: 365,
});

const nationalSeries = computed(() => {
  if (displayNational.value) {
    return Object.values(filterSeries(nationalTrendSeriesResponse.value?.data.results || []));
  } else {
    return null;
  }
});

const isNationalLevel = computed(() => {
  return queryParams.location.toValue() === queryParams.countryid.toValue();
});

const showTrends = computed(() => {
  return parseInt(queryParams.geocoding.toValue(), 10) === OS_GEOCODINGS.countries || !isNationalLevel.value;
});

watchEffect(() => {
  let responseOperators: Record<string, Operator> | undefined;

  if (showTrends.value) {
    responseOperators = trendSeriesResponse.value?.data.operators;
  } else {
    responseOperators = geocodingConfigQuery.data.value?.data.operators;
  }

  if (!responseOperators) {
    return;
  }

  const newOperators = networksWithColors(Object.values(responseOperators)) as Operator[];
  setOperators(newOperators);
});

const focusedNetworkInSingleOperatorMode = computed(() => {
  return parseInt(queryParams.network.toValue(), 10);
});

watchEffect(() => {
  if (!homeNetwork.value || !focusedNetworkInSingleOperatorMode.value) {
    return;
  }

  const focusedNetworkIsInOperators = operators.value.find(
    (o) => o.canonical_network_id === focusedNetworkInSingleOperatorMode.value,
  );

  if (!focusedNetworkIsInOperators) {
    router.push({
      name: ROUTES.CompetitiveDetails,
      query: {
        ...route.query,
        network: homeNetwork.value.canonical_network_id,
      },
    });
  }
});

const sortingByName = computed(() => {
  return sortByRef.value === 'properties.name';
});
const displayGrid = computed(() => {
  return displayMode.value === 'grid';
});
const displayList = computed(() => {
  return displayMode.value === 'list';
});
const groupingsOS = computed(() => {
  return geocodingsWithLocations.value.filter(osGroupingsFilter);
});
const groupingsCustom = computed(() => {
  return geocodingsWithLocations.value.filter(customGroupingsFilter);
});

const nationalMetricBoxData = computed(() => {
  if (!homeNetwork.value || !currentMetric.value) {
    return null;
  }

  let data;

  if (hoveredLocationData.value?.data) {
    data = hoveredLocationData.value.data;
  } else {
    data = nationalStats.value.filter((ns) => ns.date === queryParams.endDate.toValue());
  }

  const ranksSource = getOrderedValues(data, currentMetric.value, operators.value).filter((rankSource: any) => {
    return !rankSource.operator.is_mvno; // Don't show MVNOs on the national metric box in the map view
  });

  const title = hoveredLocationData.value?.data ? hoveredLocationData.value.name : 'NATIONAL LEVEL';
  return createMetricBoxData({
    data: ranksSource,
    operators: operators.value,
    selectedMetric: currentMetric.value,
    homeNetwork: homeNetwork.value,
    compareTo: queryParams.compareTo.toValue() as CompareToValues,
    title,
  });
});
const isCurrentMetricAwardMetric = computed(() => {
  return isAwardMetric(currentMetric.value);
});

const compare = computed(() => {
  return queryParams.network.toValue() === 'all';
});

const rankingMapGeoJSON = computed(() => {
  if (!showFeatures.value) {
    return {
      ...polygonsWithMetrics.value,
      features: [],
    };
  }

  return {
    ...polygonsWithMetrics.value,
    features:
      rankedGeographies.value.map((f) => {
        const dataWithoutMvnos = f.item.filter((item: any) => !item.operatorIsMvno);
        return {
          ...f,
          item: dataWithoutMvnos,
        };
      }) || [],
  };
});

const mapData = computed(() => {
  return geocodingConfigQuery.data.value?.data.results || [];
});

const rankingMapWinnersData = computed(() => {
  if (!currentMetric.value) {
    return [];
  }

  return getWinnersForRankingMap(mapData.value, currentMetric.value, operators.value);
});

const choroplethMapGeoJSON = computed(() => {
  if (!polygonsWithMetrics.value) {
    return null;
  }

  if (!showFeatures.value) {
    return {
      ...polygonsWithMetrics.value,
      features: [],
    };
  }

  return {
    ...polygonsWithMetrics.value,
    features: polygonsWithMetrics.value.features.map((f) => {
      const data = f.properties?.data.filter(
        (datum: RankedSimpleMetric) => datum.canonical_network_id === focusedNetworkInSingleOperatorMode.value,
      );

      return {
        ...f,
        item: data,
        properties: {
          ...f.properties,
          data,
        },
      };
    }),
  };
});

const unit = computed(() => currentMetric.value?.units.short);
const exportInfo = computed(() => {
  if (!currentMetric.value) {
    return null;
  }

  return {
    ...getCustomTitleLabels(
      currentLocation.value,
      currentMetric.value,
      timeframeSettings.timeRange.value.start,
      queryParams.endDate.toValue(),
      queryParams.agg.toValue(),
      dashboardInfoResponse.value?.data,
      true,
    ),
    chartTitle: getCustomTitleLabels(
      currentLocation.value,
      currentMetric.value,
      timeframeSettings.timeRange.value.start,
      queryParams.endDate.toValue(),
      queryParams.agg.toValue(),
      dashboardInfoResponse.value?.data,
      true,
    ),
    product: 'competitive',
    // this is old, and odd. Related to showing the user that a different end date was automatically selected on account of there being no data for the selected end date
    // TODO: check if we still want this
    warning: false,
    name: currentMetric.value.name,
  };
});

const detailsMapViewClass = computed(() => {
  return {
    'Details__mapView--expanded': isExpanded.value,
  };
});

const showCompare = computed(() => {
  return displayNational.value && !isNationalLevel.value;
});

const showFeatures = computed(() => {
  // we will hide map features when on the national tab,
  // as some of the country polygons e.g. taiwan, hong kong are not detailed enough
  return queryParams.geocoding.toValue() !== OS_GEOCODINGS.countries.toString();
});

const sortedRankedGeographies = computed(() => {
  if (!homeNetwork.value) {
    return [];
  }

  const results = [...rankedGeographies.value];
  const _sortBy = sortByRef.value || `operatorsById.${homeNetwork.value.canonical_network_id}.value`;

  const compareStrings = typeof get(results[0], _sortBy) === 'string';

  results.sort((a, b) => {
    if (compareStrings) {
      const first = sortOrder.value === 1 ? a : b;
      const second = sortOrder.value === 1 ? b : a;

      return get(first, _sortBy).localeCompare(get(second, _sortBy));
    } else {
      const minuend = get(a, _sortBy) || Infinity;
      const subtrahend = get(b, _sortBy) || Infinity;
      const diff = minuend - subtrahend;
      return diff * sortOrder.value;
    }
  });

  return results;
});
const listViewOperators = computed(() => {
  if (!homeNetwork.value) {
    return [];
  }

  const operators: any[] = rankedGeographies.value[0]?.item || [];

  if (operators.length <= 1 || operators[0].canonical_network_id === homeNetwork.value.canonical_network_id) {
    return operators;
  }

  // If the home network is not the first operator, move it to the top of the list
  const homeNetworkIndex = operators.findIndex(
    (operator: Operator) => operator.canonical_network_id === homeNetwork.value?.canonical_network_id,
  );

  // no home network found
  if (homeNetworkIndex === -1) {
    return operators;
  }

  // switch first with homeNetworkIndex
  const temp = operators[0];
  operators[0] = operators[homeNetworkIndex];
  operators[homeNetworkIndex] = temp;

  return operators;
});
const listViewDataRows = computed(() => {
  return sortedRankedGeographies.value.map((area, i) => {
    const index = i + 1;
    const { confidenceIntervals, ranks, scores } = listViewOperators.value.reduce(
      (acc, datum: any) => {
        const currentOperator = area.operatorsById[datum.canonical_network_id];

        acc.scores.push(currentOperator?.value || '-');
        acc.confidenceIntervals.push(`${currentOperator?.lci ?? ''} - ${currentOperator?.uci ?? ''}`);
        acc.ranks.push(currentOperator?.rank);
        return acc;
      },
      { scores: [], confidenceIntervals: [], ranks: [] } as {
        scores: string[];
        confidenceIntervals: string[];
        ranks: number[];
      },
    );

    return {
      index,
      properties: area.properties,
      scores,
      confidenceIntervals,
      ranks,
    };
  });
});

onMounted(() => {
  track('competitive');
});

// Methods
const listViewCsvExport = () => {
  if (!exportInfo.value) {
    return;
  }

  const headerExtraCols = Array.from({ length: listViewOperators.value.length - 1 }, () => '');
  const headers = [
    '#',
    queryParams.geocoding.toValue() === '3' ? 'City' : 'Region',
    'Score',
    ...headerExtraCols,
    'Confidence Interval',
    ...headerExtraCols,
    'Rank',
    ...headerExtraCols,
  ];

  const operatorNames = listViewOperators.value.map((d) => d.operator.name_mapped);

  const firstRow = ['', '', ...operatorNames, ...operatorNames, ...operatorNames];

  const rows = [headers, firstRow];
  listViewDataRows.value.forEach((row) => {
    const {
      confidenceIntervals,
      index,
      properties: { name },
      ranks,
      scores,
    } = row;
    const rowValues = [
      index,
      name,
      ...scores,
      ...confidenceIntervals,
      ...ranks.map((rank: string | number) => rank || '-'),
    ];

    rows.push(rowValues);
  });

  const { aggregation, endDate, fullTitle, geography, name } = exportInfo.value;
  const filename = `${name || fullTitle} for ${geography} ${aggregation} ${endDate}.csv`;

  exportToCsv(filename, rows);
};

const sort = (by: string, order = -1) => {
  if (sortByRef.value === by) {
    sortOrder.value = sortOrder.value * -1;
  } else {
    sortByRef.value = by;
    sortOrder.value = order;
  }
};

const sortAlphabetically = () => {
  sort('properties.name', 1);
};

const createOperatorsByIdSortString = (operatorId: number, sortBy: string) => {
  return `operatorsById.${operatorId}.${sortBy}`;
};

const sortByOperatorProp = (operatorId: number, sortBy: string, sortOrder: number) => {
  sort(createOperatorsByIdSortString(operatorId, sortBy), sortOrder);
};

const isSortingByOperatorProp = (operatorId: number, sortBy: string) => {
  return sortByRef.value === createOperatorsByIdSortString(operatorId, sortBy);
};

const setDisplayMode = (newDisplayMode: DisplayMode) => {
  displayMode.value = newDisplayMode;
};

const setHoveredLocationData = (data: any) => {
  if (queryParams.geocoding.toValue() === '1') {
    return;
  }

  hoveredLocationData.value = data
    ? {
        name: data.locationName,
        data: rankedGeographies.value.find((area) => area.id === data.locationData?.location)?.item,
      }
    : undefined;
};

const getTabNameForGrouping = (grouping: GeocodingConfig & { name: string }) => {
  return grouping.client === 'opensignal'
    ? TAB_NAMES_BY_GRANULARITY[grouping.granularity as keyof typeof TAB_NAMES_BY_GRANULARITY]
    : grouping.name;
};

const hasDrilldownToFocus = computed(() => {
  if (!currentMetric.value) {
    return false;
  }

  return (
    SPOTLIGHT_TO_FOCUS_ALLOWED.includes(currentMetric.value.subtype) &&
    ['5g', 'lte', '3g'].includes(currentMetric.value.type) &&
    ['main'].includes(currentMetric.value.subcategory)
  );
});

const createFocusDrillDownURL = (metric: MetricDescriptor, locationKey: string) => {
  const queryParam = [
    ['location', locationKey.toString()],
    ['metricSubtype', metric.subtype],
    ['endDate', queryParams.endDate.toValue()],
    ['aggregation', queryParams.agg.toValue()],
    ['chartConnectionCategories', metric.type],
    ['mapConnectionCategory', metric.type],
    ['showConfidenceIntervals', queryParams.showConfidenceIntervals.toValue().toString()],
    ...selectedOperators.value.map((networkOp) => ['operators', networkOp.canonical_network_id.toString()]),
  ];

  const urlSearchParams = new URLSearchParams(queryParam);

  const url = `/focus/qoe/details/?${urlSearchParams.toString()}`;

  return url;
};

const handlePromotionDialogUpdate = (newValue: boolean) => {
  isOpenFocusPromotionDialog.value = newValue;
};

const drillDownIntoFocus = (locationKey: string) => {
  if (!hasDrilldownToFocus.value || !user.value || !currentMetric.value) {
    return;
  }

  if (user.value.dashboards.includes('focus')) {
    const url = createFocusDrillDownURL(currentMetric.value, locationKey);
    window.open(url, '_blank');
  } else {
    handlePromotionDialogUpdate(true);
  }
};

const minAndMax = (list: any[]) => {
  return metricMinAndMax(list, currentMetric.value);
};

const updateRoute = (newQueryParams: LocationQuery) => {
  router.push({
    name: ROUTES.CompetitiveDetails,
    query: {
      ...route.query,
      ...newQueryParams,
    },
  });
};

const onTabChange = (tab: string, granularityId: string) => {
  sortByRef.value = undefined;
  sortOrder.value = -1;

  updateRoute({
    location: currentCountry.value.key,
    geocoding: granularityId,
  });
};

const navigateToPoint = (endDate: string) => {
  queryParams.endDate.onChange(endDate);
};

/**
 * geocoding_config query brings map data for the whole country. Some of the operators are regional and should only be shown in those regions.
 * The information in this query is insufficient to filter the operators by location.
 *
 * However, the trend series' query is location specific, so we can use those operators instead.
 */
const rankingMapAvailableNetworks = computed(() => {
  const allOperatorsInCountry = geocodingConfigQuery.data.value?.data.operators;

  if (!allOperatorsInCountry) {
    return [];
  }

  if (isNationalLevel.value) {
    return Object.values(allOperatorsInCountry);
  }

  const locationSpecificOperators = trendSeriesResponse.value?.data.operators;

  if (!locationSpecificOperators) {
    return [];
  }

  return Object.values(locationSpecificOperators);
});

const imageExportLegendDataPoints = computed(() => {
  if (!currentMetric.value) {
    return [];
  }

  const selectedOperatorIds = !compare.value
    ? [focusedNetworkInSingleOperatorMode.value]
    : selectedOperators.value.map((o) => o.canonical_network_id);
  const data = trendSeriesResponseResults.value
    .filter((datum) => {
      return (
        selectedOperatorIds.includes(datum.canonical_network_id) && datum.date === currentEndDate.value.toISOString()
      );
    })
    .map((datum) => {
      const operator = operators.value.find((operator) => operator.canonical_network_id === datum.canonical_network_id);
      return {
        ...datum,
        ...operator,
        y: getDatumValue(datum),
        lci: datum.lci,
        uci: datum.uci,
      };
    });

  return sortByMetricMeanAndRank(data, currentMetric.value.bigger_is_better);
});

const onMapLocationUpdate = (featureLocationId: number) => {
  let newLocation: number;
  if (featureLocationId === locationId.value) {
    newLocation = currentCountryId.value;
  } else {
    newLocation = featureLocationId;
  }

  queryParams.location.onChange(newLocation.toString());
};
</script>

<template>
  <div class="Details">
    <SpotlightByGeographyLayout v-if="homeNetwork && currentMetric">
      <template #header>
        <OnxSpotlightBreadcrumbs />
        <OnxSpotlightHeaderNav>
          <template #tabs>
            <OnxTabsContext :selected-tab="queryParams.geocoding.selectedValue.value">
              <OnxTabs @change="onTabChange">
                <OnxTab
                  v-for="grouping in [...groupingsOS, ...groupingsCustom]"
                  :id="grouping.id.toString()"
                  :key="grouping.id"
                  :value="grouping.id.toString()"
                >
                  {{ getTabNameForGrouping(grouping) }}
                </OnxTab>
              </OnxTabs>
            </OnxTabsContext>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #map>
        <div class="map-container">
          <OnxCollapsible :collapsed="!matches.laptop.value">
            <template #default="collapsible">
              <CompetitiveTile
                v-if="nationalMetricBoxData"
                :metric="nationalMetricBoxData.rawMetric"
                :bigger-better="nationalMetricBoxData.rawMetric.bigger_is_better"
                :title="nationalMetricBoxData.title"
                :ranks="nationalMetricBoxData.data"
                :is-loading="isNationalDataLoading"
                :collapsed="collapsible.collapsed || !nationalMetricBoxData.data"
                :hide-content="collapsible.collapsed || !nationalMetricBoxData.data"
                :hide-actions="collapsible.collapsed || !nationalMetricBoxData.data"
                :class="{ collapsed: collapsible.collapsed }"
                @header:click="!matches.laptop.value && collapsible.toggle()"
                :home-network="homeNetwork"
                :show-confidence-intervals="queryParams.showConfidenceIntervals.isActive.value"
              >
                <template #title="titleProps">
                  <div class="onx-national-card__title-container">
                    <OnxHeadline as="h4" class="onx-national-card__title">
                      {{ (hoveredLocationData?.data && hoveredLocationData?.name) || 'NATIONAL LEVEL' }}

                      <CustomTooltip
                        v-if="isCurrentMetricAwardMetric"
                        :message="
                          TOOLTIP_MESSAGES[
                            `availabilityAward_${queryParams.userGroup.selectedValue.value}` as keyof typeof TOOLTIP_MESSAGES
                          ]
                        "
                        placement="top"
                      >
                        <AwardIcon />
                      </CustomTooltip>
                    </OnxHeadline>

                    <template v-if="!matches.laptop.value">
                      <OnxCaption v-if="collapsible.collapsed" variant="md">
                        {{ titleProps.rank }} / {{ titleProps.value }}
                      </OnxCaption>

                      <ChevronDownIcon
                        class="onx-dropdown-icon"
                        :class="{ 'onx-dropdown-icon--reverse': !collapsible.collapsed }"
                      />
                    </template>
                  </div>
                </template>

                <template v-if="!collapsible.collapsed && !hoveredLocationData" #actions>
                  <OnxButton
                    variant="tertiary"
                    size="sm"
                    :disabled="!hasDrilldownToFocus"
                    @click="drillDownIntoFocus(currentCountry?.key)"
                  >
                    <span>Metric drilldown</span>
                    <ChevronRightIcon small />
                  </OnxButton>
                </template>
              </CompetitiveTile>
            </template>
          </OnxCollapsible>

          <ChartWrapper :loading="polygonQueriesLoading" class="Details__mapWrapper" disable-title disable-export>
            <div v-if="compare" class="Details__mapView Details__mapView--ranking" :class="detailsMapViewClass">
              <RankingMap
                :model-value="locationId"
                @update:model-value="onMapLocationUpdate"
                :geo-json="rankingMapGeoJSON"
                :choropleth-data="rankingMapWinnersData"
                :networks="selectedOperators"
                :zoomed="locationId !== currentCountryId"
                :current-country="currentCountryId"
                display-rank
                :available-networks="rankingMapAvailableNetworks"
                @location-mouseover="setHoveredLocationData"
                @location-mouseout="setHoveredLocationData(null)"
                :current-metric="currentMetric"
              />
            </div>
            <div
              v-else-if="choroplethMapGeoJSON"
              class="Details__mapView etails__mapView--choropleth"
              :class="detailsMapViewClass"
            >
              <ChoroplethMap
                :model-value="locationId"
                @update:model-value="onMapLocationUpdate"
                :geo-json="choroplethMapGeoJSON"
                :choropleth-data="mapData"
                :color-scale="colorScaleIntervals"
                :bigger-is-better="currentMetric.bigger_is_better"
                :zoomed="locationId !== currentCountryId"
                display-rank
                :network-id="focusedNetworkInSingleOperatorMode"
                @location-mouseover="setHoveredLocationData"
                @location-mouseout="setHoveredLocationData(null)"
              />
            </div>
          </ChartWrapper>
        </div>
      </template>

      <br />

      <template #charts>
        <ChartWrapperExtended
          v-if="showTrends"
          :loading="isTrendSeriesLoading"
          :picture-meta="exportInfo"
          :selected-point="imageExportLegendDataPoints"
          :title="'Competitive Trends'"
          :title-labels="exportInfo"
          :show-confidence-rate="queryParams.showConfidenceIntervals.isActive.value"
          :unit="unit"
          :compare="displayNational"
          :chart-data="trendSeries"
          enable-compare
          @compare="displayNational = !displayNational"
          :on-fetch-older-trends="onFetchOlderTrends"
          :timeframe-settings="timeframeSettings"
          :raw-data="trendSeriesResponseResults"
          :current-metric="currentMetric"
        >
          <line-chart
            :data-set="Object.values(trendSeries)"
            :compare-set="nationalSeries"
            :date="queryParams.endDate.selectedValue.value"
            :title="currentMetric && currentMetric.name"
            :y-axis-unit="unit"
            :end-date="lastDateAvailable"
            :show-confidence-rate="queryParams.showConfidenceIntervals.isActive.value"
            :show-line-icons="true"
            :height="350"
            :show-compare="showCompare"
            chart-id="trend"
            class="Details__chartView"
            show-rank-gaps
            @point="navigateToPoint"
          />
        </ChartWrapperExtended>

        <template v-else-if="!polygonQueriesLoading && rankedGeographies.length">
          <div class="container--spacing-x container--spacing-b">
            <OnxPaper class="Details__content__title-bar">
              <DownloadFileIcon
                v-if="displayList"
                button
                data-test-id="spotlight-details_list-view_csv-export-btn"
                @click="listViewCsvExport"
              />
              <AlphaSortIcon
                button
                :active="sortingByName"
                :ascending="sortingByName ? sortOrder === 1 : true"
                data-test-id="spotlight-details_sort-btn"
                @click="sortAlphabetically"
              />
              <GridIcon
                button
                :active="displayGrid"
                data-test-id="spotlight-details_display-grid-btn"
                @click="setDisplayMode('grid')"
              />
              <ListIcon
                button
                :active="displayList"
                data-test-id="spotlight-details_display-list-btn"
                @click="setDisplayMode('list')"
              />
            </OnxPaper>
          </div>

          <div v-if="displayGrid" class="region-city-metric-box-grid onx-grid fluid">
            <OnxMetricBox
              v-for="(area, i) in sortedRankedGeographies"
              :key="area.index"
              :bigger-better="currentMetric.bigger_is_better"
              :ranks="area.item"
              :min-and-max="minAndMax(area.item)"
              :show-confidence-intervals="queryParams.showConfidenceIntervals.isActive.value"
            >
              <template #header>
                <OnxHeadline as="h4">
                  {{ area.properties.name }}
                </OnxHeadline>
                <OnxCaption>#{{ i + 1 }}/{{ rankedGeographies.length }} in list</OnxCaption>
              </template>

              <template #footer>
                <OnxButton variant="tertiary" size="sm" @click="updateRoute({ location: area.properties.id })">
                  <span>View details</span>
                  <ChevronRightIcon small />
                </OnxButton>

                <OnxButton
                  variant="tertiary"
                  size="sm"
                  :disabled="!hasDrilldownToFocus"
                  @click="drillDownIntoFocus(area.properties.id)"
                >
                  <span>Metric drilldown</span>
                  <ChevronRightIcon small />
                </OnxButton>
              </template>
            </OnxMetricBox>
          </div>

          <div v-if="displayList" class="container--spacing-x container--spacing-b">
            <OnxPaper class="Details__content__list-view">
              <table class="onx-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                      {{ queryParams.geocoding.selectedValue.value === '3' ? 'City' : 'Region' }}
                    </th>
                    <th scope="colgroup" :colspan="listViewOperators.length">Score</th>
                    <th scope="colgroup" :colspan="listViewOperators.length">Confidence Interval</th>
                    <th scope="colgroup" :colspan="listViewOperators.length">Rank</th>
                    <th scope="col" />
                  </tr>
                  <tr>
                    <th scope="col" />
                    <th scope="col" />

                    <th
                      v-for="datum in listViewOperators"
                      :key="datum.operator.name_mapped"
                      scope="col"
                      class="interactive"
                      :class="{
                        'Details__content__list-view__header--highlighted': isSortingByOperatorProp(
                          datum.canonical_network_id,
                          'value',
                        ),
                      }"
                      @click="sortByOperatorProp(datum.canonical_network_id, 'value', -1)"
                    >
                      <div class="Details__content__list-view__header__content">
                        <OperatorAvatar
                          :background-color="fixHexColor(datum.operator.hex_color)"
                          :name="datum.operator.name_mapped"
                          :background-style="datum.operator.is_mvno ? 'outline' : 'fill'"
                        />

                        <SortOrderIcon class="visibility-hidden" active :ascending="sortOrder === 1" />
                      </div>
                    </th>

                    <th v-for="datum in listViewOperators" :key="datum.operator.name_mapped" scope="col">
                      <OperatorAvatar
                        :background-color="fixHexColor(datum.operator.hex_color)"
                        :name="datum.operator.name_mapped"
                        :background-style="datum.operator.is_mvno ? 'outline' : 'fill'"
                      />
                    </th>

                    <th
                      v-for="datum in listViewOperators"
                      :key="datum.operator.name_mapped"
                      scope="col"
                      class="interactive"
                      :class="{
                        'Details__content__list-view__header--highlighted': isSortingByOperatorProp(
                          datum.canonical_network_id,
                          'rank',
                        ),
                      }"
                      @click="sortByOperatorProp(datum.canonical_network_id, 'rank', 1)"
                    >
                      <div class="Details__content__list-view__header__content">
                        <OperatorAvatar
                          :background-color="fixHexColor(datum.operator.hex_color)"
                          :name="datum.operator.name_mapped"
                          :background-style="datum.operator.is_mvno ? 'outline' : 'fill'"
                        />

                        <SortOrderIcon class="visibility-hidden" active :ascending="sortOrder === 1" />
                      </div>
                    </th>

                    <th scope="col" />
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="row in listViewDataRows" :key="row.index">
                    <td>{{ row.index }}.</td>
                    <td>{{ row.properties.name }}</td>
                    <td v-for="(score, i) in row.scores" :key="`${row.properties.name}-${score}-${i}`">
                      {{ score }}
                    </td>
                    <td
                      v-for="(interval, i) in row.confidenceIntervals"
                      :key="`${row.properties.name}-${interval}-${i}`"
                    >
                      {{ interval }}
                    </td>
                    <td v-for="(rank, i) in row.ranks" :key="`${row.properties.name}-${rank}-${i}`">
                      {{ getRankLabel(rank) }}
                    </td>
                    <td>
                      <VDropdown :distance="6">
                        <span>
                          <MenuDotsIcon button data-test-id="spotlight-details_list-view_menu-btn" />
                        </span>

                        <template #popper>
                          <OnxPaper :depth="3">
                            <OnxList>
                              <OnxListItem
                                extra-x-padding
                                data-test-id="spotlight-details_list-view_view-details-btn"
                                @click="updateRoute({ location: row.properties.id })"
                              >
                                <OnxListItemText size="sm"> View details </OnxListItemText>
                              </OnxListItem>

                              <OnxListItem
                                extra-x-padding
                                :disabled="!hasDrilldownToFocus"
                                data-test-id="spotlight-details_list-view_metric-drilldown-btn"
                                @click="drillDownIntoFocus(row.properties.id)"
                              >
                                <OnxListItemText size="sm"> Metric drilldown </OnxListItemText>
                              </OnxListItem>
                            </OnxList>
                          </OnxPaper>
                        </template>
                      </VDropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <OnxParagraph size="small" class="Details__content__list-view__copyright">
                {{ COPYRIGHT }}
              </OnxParagraph>
            </OnxPaper>
          </div>
        </template>
      </template>
    </SpotlightByGeographyLayout>
  </div>
  <SimpleDialog :open="isOpenFocusPromotionDialog" @update:open="handlePromotionDialogUpdate">
    <template #default>
      <FocusPromotion />
    </template>
  </SimpleDialog>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;
@import 'scss/onx-breakpoints.module';

$map-height: 560px;

.onx-table {
  table-layout: auto;
}

.Details {
  .map-container {
    position: relative;

    .onx-metric-box {
      z-index: 1;
      position: absolute;
      top: 8px;
      left: 8px;
      width: 360px;

      @include desktop {
        top: 16px;
        left: 16px;
      }

      .onx-metric-box__header {
        display: block;
        padding-top: 12px;
        padding-bottom: 8px;

        .onx-caption {
          font-weight: 400;
        }
      }
    }
  }

  .onx-metric-box.collapsed .onx-metric-box__header {
    height: 44px;
    padding-bottom: 0;
  }

  .onx-national-card__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  .onx-national-card__title {
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .ChartWrapperExtended {
    margin: 0 16px;
    padding: 16px 24px;

    &--extra-top-margin {
      margin: 32px 16px 0 16px;
    }
  }
}

.Details__sidebar {
  @include xy-grid(vertical, $wrap: false);
  height: calc(100vh - 2rem);

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-5gIcon {
    margin-right: 10px;
    width: 47px;
    height: 44px;
  }

  &-title {
    padding-bottom: 0 !important;
  }

  &-title-inner {
    display: flex;
    align-items: center;
    margin-left: -15px;
  }
}

@media screen and (max-width: $ci-breakpoint) {
  .Details__sidebar {
    height: initial;
  }
}

.Details__mapWrapper {
  height: $map-height;
  margin-bottom: 16px;

  .ChartWrapper__placeholder {
    width: auto;
    height: $map-height;
  }
}

.Details__mapView {
  height: $map-height;
}

.Details__content {
  &__title-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    padding: 0 8px;
    height: 40px;
  }

  &__list-view {
    padding: 16px 0;
    overflow: auto;

    tbody tr td {
      &:last-child {
        width: 30px;
        text-align: right;
      }

      &:first-child,
      &:nth-child(2) {
        font-weight: 700;
        font-size: pxToRem(12);
      }
    }

    &__header--highlighted {
      border-bottom: 2px solid var(--teal-500);

      & i.onx-icon {
        visibility: visible;
      }
    }

    &__header__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__copyright {
      text-align: center;
      margin-top: 16px;
      margin-bottom: 0 !important;
    }
  }
}
</style>
