<script setup>
import { computed } from 'vue';
import focusLogo from '@/assets/onx/logo/onxfocus.svg';
import spotlightLogo from '@/assets/onx/logo/onxspotlight.svg';
import threeSixtyLogo from '@/assets/onx/logo/onx360.svg';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import LogoutIcon from '@/components/onx/icons/LogoutIcon';
import QuestionIcon from '@/components/onx/icons/QuestionIcon';
import OnxAppSwitch from '@/components/onx/OnxAppSwitch';
import OnxPaper from '@/components/onx/OnxPaper';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import OnxSpotlightFilters from '@/components/onx/OnxSpotlightFilters';
import FocusMenu from '@/focus/focus-menu/FocusMenu.vue';
import ThreeSixtyMenu from '@/360/ThreeSixtyMenu.vue';
import { useProduct, PRODUCT } from '@/composables/useProduct';
import OnxSpotlightPulse from '@/components/onx/OnxPulse/OnxSpotlightPulse.vue';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useAuth from '@/composables/useAuth';

const { logout } = useAuth();

const matches = useBreakpoints();
const { product } = useProduct();

const dashboard = useCurrentDashboardName();
const { data } = useDashboardInfo(dashboard.value);
const canUseSpotlightPulse = computed(() => {
  // refactor into the useOnxLicensePermission composable at some point
  return data.value?.data.include_spotlight_pulse;
});

const productLogo = computed(() => {
  switch (product.value) {
    case PRODUCT.SPOTLIGHT:
      return spotlightLogo;
    case PRODUCT.FOCUS:
      return focusLogo;
    case PRODUCT.THREE_SIXTY:
      return threeSixtyLogo;
    default:
      return null;
  }
});

const helpURL = computed(() => {
  switch (product.value) {
    case PRODUCT.SPOTLIGHT:
      return 'https://support.opensignal.com/hc/en-us/sections/10960163764119-ONX-Spotlight';
    case PRODUCT.FOCUS:
      return 'https://support.opensignal.com/hc/en-us/sections/10960165040407-ONX-Focus';
    // case PRODUCT.THREE_SIXTY: nothing for Three Sixty yet, so commented out
    default:
      return null;
  }
});
</script>

<template>
  <OnxPaper class="onx-navbar">
    <div class="onx-navbar__logo">
      <img v-if="matches.laptop.value" src="@/assets/onx/logo/opensignal.svg" alt="Opensignal Logo" />

      <img v-else :src="productLogo" alt="Opensignal Logo" />
    </div>

    <template v-if="!matches.laptop.value">
      <OnxSpotlightFilters class="onx-navbar__filters">
        <OnxSidebar>
          <FocusMenu v-if="product === PRODUCT.FOCUS" />
          <CompetitiveMenu v-if="product === PRODUCT.SPOTLIGHT" />
          <ThreeSixtyMenu v-if="product === PRODUCT.THREE_SIXTY" />
        </OnxSidebar>
      </OnxSpotlightFilters>
    </template>

    <OnxSpotlightPulse v-if="product === PRODUCT.SPOTLIGHT && canUseSpotlightPulse" />
    <OnxAppSwitch />

    <template v-if="matches.laptop.value && helpURL !== null">
      <a :href="helpURL" style="display: inline-flex" target="_blank">
        <QuestionIcon button style="margin-right: 12px" />
      </a>
    </template>

    <LogoutIcon v-if="matches.laptop.value" button @click="logout" data-test-id="onx-navbar_logout-btn" />
  </OnxPaper>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.onx-navbar {
  position: relative;
  display: flex;
  align-items: center;
  height: var(--onx-navbar-height);
  padding: 9px 0;
  justify-content: space-between;
  z-index: 101;

  @include laptop {
    padding: 9px 18px;
  }
}

.onx-navbar__logo {
  flex-grow: 1;
  margin-left: pxToRem(16);
  height: 32px;

  @include laptop {
    margin-left: 0;
  }

  img {
    height: 100%;
  }
}

.onx-navbar__filters {
  color: var(--teal-500);
  width: pxToRem(48);
  height: pxToRem(48);
}
</style>
