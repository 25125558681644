<script setup lang="ts">
import { onMounted, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { deployedSpectrumChartMetricDefinitions } from './deployedSpectrumMetrics';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import { Location } from '@/types/Location';

import useAnalytics from '@/composables/useAnalytics';

import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import { OS_GEOCODINGS } from '@/constants/constants';

const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { aggregation } = useFilters();
const { track } = useAnalytics();
const { currentCountry, currentLocation, locationId, locationsInCurrentCountry } = useLocations();
const router = useRouter();
const route = useRoute();

const mapclasses = 'sm:onx-col-5 md:onx-col-8 chart-height';
const mapMetrics = [
  {
    title: 'Deployed Bands',
    endpoint: 'maps/deployed-spectrum-deployed-bands',
    chartContainerClass: mapclasses,
    id: 'deployed-spectrum/deployed-spectrum-deployed-bands',
  },
  {
    title: 'Deployed Bandwidths',
    endpoint: 'maps/deployed-spectrum-deployed-bandwidths',
    chartContainerClass: mapclasses,
    id: 'deployed-spectrum/deployed-spectrum-deployed-bandwidths',
  },
  {
    title: 'Deployed Channels',
    endpoint: 'maps/deployed-spectrum-deployed-channels',
    chartContainerClass: mapclasses,
    id: 'deployed-spectrum/deployed-spectrum-deployed-channels',
  },
];

onMounted(() => {
  track('focus ran health deployed spectrum page view');
});

const regionGranularity = `${OS_GEOCODINGS.regions}`;
watchEffect(() => {
  const _currentCountry = currentCountry.value;
  if (!_currentCountry?.iso3) {
    // can't do anything if we don't have a current country
    return;
  }

  const _currentLocation = currentLocation.value;
  if (
    _currentLocation &&
    _currentLocation.granularityId === regionGranularity &&
    _currentLocation.iso3 === _currentCountry.iso3
  ) {
    // if we have a region selected, we're good
    return;
  }

  // otherwise we need to select a new region
  const firstRegion = locationsInCurrentCountry.value.find((_location: Location) => {
    return _location.granularityId === regionGranularity;
  });

  // if we can find a valid region, add it to the route as a query param
  // which will update the maps/tables/etc.
  if (firstRegion) {
    router.push({
      query: {
        ...route.query,
        geohashes: [],
        location: firstRegion.key,
        operators: undefined,
      },
    });
  }
});
</script>

<template>
  <div v-if="selectedOperators" :key="locationId">
    <div class="onx-grid">
      <OnxSwyftMap
        v-for="metric in mapMetrics"
        :key="metric.id"
        :map-endpoint="metric.endpoint"
        :title="metric.title"
        :network-operators="selectedOperators"
        :class="metric.chartContainerClass"
        :geohash-level="7"
        :location-id="locationId"
      />

      <template
        v-for="metric in deployedSpectrumChartMetricDefinitions"
        :key="`${metric.metricSubtype || metric.dataset}`"
      >
        <ChartManager
          :dashboard="Dashboards.Focus"
          :metric="metric"
          :location="locationId"
          :geohashes="[]"
          :aggregation="aggregation"
          :operators="selectedOperators"
        />
      </template>
    </div>
  </div>
</template>
