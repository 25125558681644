import { DatasetTransformFilterArgs } from '@/chart-metric-definitions/data-transformers/datasetTransformFilterArgs';
import { Column } from '@/components/onx/table';
import { Operator } from '@/types/Operator';

export type DeployedSpectrumDatasetRow = {
  location: number; // 69,
  canonical_network_id: number; // 23430,
  location_city: string; // "London",
  deployed_bands: string; // "20,3,3,1,7,7,7,3,1",
  deployed_frequencies: string; // "798.5,1846.7,1866.5,2162.2,2647.6,2662.9,2680,1864.1,2159.7",
  deployed_bandwidths: string; // "5,20,20,15,15,15,20,15,20",
  sum_deployedbandwidth: number; // 145.0,
  max_deployedbandwidthwithca: number; // 175.0,
  count_enodebid: number; // 3458,
  location_population: number; // 1295604.5,
  count_deployedbands: number; // 9,
  date: string; // "2025-02-09T00:00:00.000Z",

  operator?: Operator; // added by transformer function below
};

export const deployedSpectrumTableTransformer = (params: DatasetTransformFilterArgs) => {
  return (rows: DeployedSpectrumDatasetRow[]) => {
    return rows.map<DeployedSpectrumDatasetRow & { operator: Operator | undefined }>((row) => {
      const operator = params.operators.find((operator) => operator.canonical_network_id === row.canonical_network_id);

      return {
        ...row,
        operator,
      };
    });
  };
};

export const deployedSpectrumDetailsColumns: Column<DeployedSpectrumDatasetRow>[] = [
  {
    value: (row) => row.location_city,
    header: 'City',
    key: 'location_city',
    sort: (a: string, b: string) => a.localeCompare(b),
  },
  {
    key: 'canonical_network_id',
    header: 'Operator',
    value(row) {
      return row.canonical_network_id;
    },
    cell(row) {
      return `${row.operator?.name_mapped || row.canonical_network_id}`;
    },
    sort: (a: string, b: string) => a.localeCompare(b),
  },
  {
    key: 'deployed_bands',
    header: 'Deployed Bands',
    value(row) {
      return row.deployed_bands;
    },
  },
  {
    key: 'deployed_bandwidths',
    header: 'Deployed Bandwidths',
    value(row) {
      return row.deployed_bandwidths;
    },
  },
  {
    key: 'deployed_frequencies',
    header: 'Deployed Frequencies',
    value(row) {
      return row.deployed_frequencies;
    },
  },
  {
    key: 'sum_deployedbandwidth',
    header: 'Sum of Deployed Bandwidth',
    value(row) {
      return row.sum_deployedbandwidth;
    },
    sort: (a: number, b: number) => a - b,
  },
  {
    key: 'max_deployedbandwidthwithca',
    header: 'Maximum Deplyed Bandwidth',
    value(row) {
      return row.max_deployedbandwidthwithca;
    },
    sort: (a: number, b: number) => a - b,
  },
  {
    key: 'count_deployedbands',
    header: 'Bands Count',
    value(row) {
      return row.count_deployedbands;
    },
    sort: (a: number, b: number) => a - b,
  },
  {
    key: 'count_enodebid',
    header: 'eNodeB ID Count',
    value(row) {
      return row.count_enodebid;
    },
    sort: (a: number, b: number) => a - b,
  },
  {
    key: 'location_population',
    header: 'Population',
    value(row) {
      return row.location_population.toFixed(0);
    },
    sort: (a: number, b: number) => a - b,
  },
];
