import { computed } from 'vue';

import useAvailableChartMetricDefinitionsForSelector from '@/focus/metric-selector/useAvailableChartMetricDefinitionsForSelector';

import { MetricSubtypes } from '@/types/MetricSubtypes';

import getLabelForMetricSubtype from '@/focus/metric-selector/labelsByMetricSubtype';

const useAvailableChartMetricsForSelector = () => {
  const metricDefinitions = useAvailableChartMetricDefinitionsForSelector();

  return computed(() => {
    const uniqueSubtypes = new Set(metricDefinitions.value.map((metricDefinition) => metricDefinition.chartGroup));
    return [...uniqueSubtypes]
      .filter((subtype): subtype is MetricSubtypes => subtype !== undefined)
      .map((subtype) => ({
        metricSubtype: subtype,
        label: getLabelForMetricSubtype(subtype),
      }));
  });
};

export default useAvailableChartMetricsForSelector;
