import { authGuard } from '@auth0/auth0-vue';
import { createRouter, createWebHistory } from 'vue-router';
import ROUTES from '@/constants/routes';
import Landing from '@/views/Landing.vue';
import LogoutCallback from '@/views/LogoutCallback.vue';

import { RANHealthTitles, MetricSubtypeTitles } from '@/focus/constants/titles';
import FocusCoreHealthHeader from '@/focus/core-health/FocusCoreHealthHeader';
import Focus from '@/focus/Focus';
import FocusQoEDetails from '@/focus/qoe/FocusQoEDetails';
import RouterDefaultPassThrough from '@/components/RouterDefaultPassThrough';
import FocusQoEHeader from '@/focus/qoe/FocusQoEHeader';
import FocusRANHealthHeader from '@/focus/ran-health/FocusRANHealthHeader';
import FocusRANHealthCoverage from '@/focus/ran-health/FocusRANHealthCoverage.vue';
import FocusRANHealthNetworkRollout from '@/focus/ran-health/FocusRANHealthNetworkRollout.vue';
import FocusRANHealthDeployedSpectrum from '@/focus/ran-health/deployed-spectrum/FocusRANHealthDeployedSpectrum.vue';
import FocusRANHealthSiteMapper from '@/focus/ran-health/FocusRANHealthSiteMapper/FocusRANHealthSiteMapper.vue';
import FocusCoreHealthByCDN from '@/focus/core-health/FocusCoreHealthByCDN';
import FocusCoreHealthByOperatorCDN from '@/focus/core-health/FocusCoreHealthByOperatorCDN.vue';

import Spotlight from '@/spotlight/Spotlight.vue';
import SpotlightByConnectionCategory from '@/spotlight/SpotlightByConnectionCategory.vue';
import SpotlightByGeography from '@/spotlight/SpotlightByGeography.vue';
import SpotlightOverview from '@/spotlight/SpotlightOverview.vue';

import ThreeSixty from '@/360/ThreeSixty.vue';
import ThreeSixtyCoverageHeader from '@/360/coverage/ThreeSixtyCoverageHeader.vue';
import ThreeSixtyBaseStationHeader from '@/360/base-station/ThreeSixtyBaseStationHeader.vue';
import ThreeSixtyCoreHeader from '@/360/core/ThreeSixtyCoreHeader.vue';

import ThreeSixtyNetworkCoverage from '@/360/coverage/ThreeSixtyNetworkCoverage.vue';
import ThreeSixtyCoverageAvailability from '@/360/coverage/ThreeSixtyCoverageAvailability.vue';
import ThreeSixtyCoverageAvailability5g from '@/360/coverage/ThreeSixtyCoverageAvailability5g.vue';
import ThreeSixtyBaseStationSiteMapper from '@/360/base-station/ThreeSixtyBaseStationSiteMapper.vue';
import ThreeSixtyBaseStationCellCongestion from '@/360/base-station/ThreeSixtyBaseStationCellCongestion.vue';
import ThreeSixtyBaseStationOvershooting from '@/360/base-station/ThreeSixtyBaseStationOvershooting.vue';
import ThreeSixtyCoreEndpointIPSpeed from '@/360/core/ThreeSixtyCoreEndpointIPSpeed.vue';
import ThreeSixtyCoreEndpointIPGamesAndVoice from '@/360/core/ThreeSixtyCoreEndpointIPGamesAndVoice.vue';
import ThreeSixtyCoreEndpointIPEcqAndCcq from '@/360/core/ThreeSixtyCoreEndpointIPEcqAndCcq.vue';
import ThreeSixtyCoreEndpointIPVideoABR from '@/360/core/ThreeSixtyCoreEndpointIPVideoABR.vue';
import ThreeSixtyCoreEndpointIPVideoLive from '@/360/core/ThreeSixtyCoreEndpointIPVideoLive.vue';

import IntlSpotlight from '@/intl-spotlight/IntlSpotlight.vue';
import IntlSpotlightOverview from '@/intl-spotlight/IntlSpotlightOverview.vue';
import IntlSpotlightOverviewMenu from '@/intl-spotlight/IntlSpotlightOverviewMenu.vue';
import IntlSpotlightOverviewHeader from '@/intl-spotlight/IntlSpotlightOverviewHeader.vue';
import IntlSpotlightByConnectionCategory from '@/intl-spotlight/IntlSpotlightByConnectionCategory.vue';
import IntlSpotlightByConnectionCategoryMenu from '@/intl-spotlight/IntlSpotlightByConnectionCategoryMenu.vue';
import IntlSpotlightByConnectionCategoryHeader from '@/intl-spotlight/IntlSpotlightByConnectionCategoryHeader.vue';

import IntlSpotlightByGeography from '@/intl-spotlight/by-geography/IntlSpotlightByGeography.vue';
import IntlSpotlightByGeographyMenu from '@/intl-spotlight/by-geography/IntlSpotlightByGeographyMenu.vue';
import IntlSpotlightByGeographyHeader from '@/intl-spotlight/by-geography/IntlSpotlightByGeographyHeader.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Spotlight National
    {
      path: '/spotlight',
      name: ROUTES.Spotlight,
      component: Spotlight,
      redirect: { name: ROUTES.CompetitiveOverview },
      meta: {
        title: 'Spotlight',
        gtm: 'Spotlight',
      },
      children: [
        {
          // path: 'overview/:location/:compareTo/:date',
          path: 'overview',
          name: ROUTES.CompetitiveOverview,
          component: SpotlightOverview,
          gtm: 'Spotlight Overview',
        },
        {
          // path: 'connection-category/:location/:geocoding/:country/:countryid/:agg/:metric/:network/:compareTo/:date',
          path: 'connection-category',
          name: ROUTES.CompetitiveConnectionCategory,
          component: SpotlightByConnectionCategory,
          gtm: 'Spotlight Connection Category',
        },
        {
          // path: 'details/:location/:geocoding/:country/:countryid/:agg/:metric/:network/:date',
          path: 'details',
          name: ROUTES.CompetitiveDetails,
          component: SpotlightByGeography,
          gtm: 'Spotlight Details',
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: ROUTES.CompetitiveOverview },
        },
      ],
    },
    // Spotlight Multicountry
    {
      path: '/spotlight-multicountry',
      name: ROUTES.IntlSpotlight,
      component: IntlSpotlight,
      redirect: { name: ROUTES.IntlSpotlightOverview },
      meta: {
        title: 'Spotlight Multi-Country',
        gtm: 'Spotlight Multi-Country',
      },
      children: [
        {
          path: 'overview',
          name: ROUTES.IntlSpotlightOverview,
          components: {
            default: IntlSpotlightOverview,
            menu: IntlSpotlightOverviewMenu,
            header: IntlSpotlightOverviewHeader,
          },
          gtm: 'Spotlight Multi-Country Overview',
        },
        {
          path: 'connection-category',
          name: ROUTES.IntlSpotlightByConnectionCategory,
          components: {
            default: IntlSpotlightByConnectionCategory,
            menu: IntlSpotlightByConnectionCategoryMenu,
            header: IntlSpotlightByConnectionCategoryHeader,
          },
          gtm: 'Spotlight Multi-Country Connection Category',
        },
        {
          path: 'details',
          name: ROUTES.IntlSpotlightByGeography,
          components: {
            default: IntlSpotlightByGeography,
            menu: IntlSpotlightByGeographyMenu,
            header: IntlSpotlightByGeographyHeader,
          },
          gtm: 'Spotlight Multi-Country By Geography',
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: ROUTES.IntlSpotlightOverview },
        },
      ],
    },
    // Focus
    {
      path: '/focus',
      name: ROUTES.Focus,
      components: {
        default: Focus,
      },
      redirect: { name: ROUTES.FocusQoEDetails },
      meta: {
        title: 'Focus',
      },
      children: [
        {
          path: 'qoe',
          name: ROUTES.FocusQoE,
          redirect: { name: ROUTES.FocusQoEDetails },
          components: {
            default: RouterDefaultPassThrough,
            header: FocusQoEHeader,
          },
          children: [
            {
              path: 'summary',
              redirect: {
                name: ROUTES.FocusQoEDetails,
              },
            },
            {
              path: 'details',
              name: ROUTES.FocusQoEDetails,
              component: FocusQoEDetails,
              meta: {
                getHeaderTitle: (route) => [
                  'Quality of Experience',
                  '\u00b7',
                  MetricSubtypeTitles[route.query.metricSubtype],
                ],
                gtm: 'Focus QoE Details',
              },
            },
          ],
        },
        {
          path: 'core-health',
          name: ROUTES.FocusCoreHealth,
          redirect: { name: ROUTES.FocusCoreHealthByCDN },
          components: {
            default: RouterDefaultPassThrough,
            header: FocusCoreHealthHeader,
          },
          children: [
            {
              path: 'by-cdn',
              name: ROUTES.FocusCoreHealthByCDN,
              component: FocusCoreHealthByCDN,
              meta: {
                getHeaderTitle: (route) => {
                  return ['Core Health', '\u00b7', 'By CDN', '\u00b7', MetricSubtypeTitles[route.query.metricSubtype]];
                },
                gtm: 'Focus Core Health By CDN',
              },
            },
            {
              path: 'by-operator-cdn',
              name: ROUTES.FocusCoreHealthByOperatorCDN,
              component: FocusCoreHealthByOperatorCDN,
              meta: {
                getHeaderTitle: (route) => {
                  return [
                    'Core Health',
                    '\u00b7',
                    'By Operator-CDN',
                    '\u00b7',
                    MetricSubtypeTitles[route.query.metricSubtype],
                  ];
                },
                gtm: 'Focus Core Health By Operator-CDN',
              },
            },
          ],
        },
        {
          path: 'ran-health',
          name: ROUTES.FocusRANHealth,
          redirect: { name: ROUTES.FocusRANHealthCoverage },
          components: {
            default: RouterDefaultPassThrough,
            header: FocusRANHealthHeader,
          },
          children: [
            {
              path: 'summary',
              redirect: {
                name: ROUTES.FocusRANHealthCoverage,
              },
            },
            {
              path: 'coverage',
              name: ROUTES.FocusRANHealthCoverage,
              meta: {
                getHeaderTitle: () => {
                  return ['Ran Health', '\u00b7', RANHealthTitles.Coverage];
                },
                gtm: 'Focus RAN Health Coverage',
              },
              component: FocusRANHealthCoverage,
            },
            {
              path: 'network-rollout',
              name: ROUTES.FocusRANHealthNetworkRollout,
              meta: {
                getHeaderTitle: () => RANHealthTitles.NetworkRollout,
                gtm: 'Focus RAN Health Network Rollout',
              },
              component: FocusRANHealthNetworkRollout,
            },
            {
              path: 'deployed-spectrum',
              name: ROUTES.FocusRANHealthDeployedSpectrum,
              meta: {
                getHeaderTitle: () => RANHealthTitles.DeployedSpectrum,
                gtm: 'Focus RAN Health Deployed Spectrum',
              },
              component: FocusRANHealthDeployedSpectrum,
            },
            {
              path: 'site-mapper',
              name: ROUTES.FocusRANHealthSiteMapper,
              meta: {
                getHeaderTitle: () => RANHealthTitles.SiteMapper,
                gtm: 'Focus RAN Health Site Mapper',
              },
              component: FocusRANHealthSiteMapper,
            },
          ],
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: ROUTES.FocusQoEDetails },
        },
      ],
    },
    // 360
    {
      path: '/360',
      name: ROUTES.ThreeSixty,
      components: {
        default: ThreeSixty,
      },
      meta: {
        title: '360',
      },
      children: [
        {
          path: 'coverage',
          name: ROUTES.ThreeSixtyCoverage,
          components: {
            default: RouterDefaultPassThrough,
            header: ThreeSixtyCoverageHeader,
          },
          children: [
            {
              path: 'network-coverage',
              name: ROUTES.ThreeSixtyCoverageNetworkCoverage,
              component: ThreeSixtyNetworkCoverage,
              meta: {
                getHeaderTitle: () => ['Coverage', '\u00b7', 'Network Coverage'],
                gtm: '360 Network Coverage',
              },
            },
            {
              path: 'availability',
              name: ROUTES.ThreeSixtyCoverageAvailability,
              component: ThreeSixtyCoverageAvailability,
              meta: {
                getHeaderTitle: () => ['Coverage', '\u00b7', 'Availability (4G Devices)'],
                gtm: '360 Availability (4G Devices)',
              },
            },
            {
              path: 'availability-5g',
              name: ROUTES.ThreeSixtyCoverageAvailability5G,
              component: ThreeSixtyCoverageAvailability5g,
              meta: {
                getHeaderTitle: () => ['Coverage', '\u00b7', 'Availability  (5G Devices)'],
                gtm: '360 Availability (5G Devices)',
              },
            },
          ],
        },
        {
          path: 'base-station',
          name: ROUTES.ThreeSixtyBaseStation,
          components: {
            default: RouterDefaultPassThrough,
            header: ThreeSixtyBaseStationHeader,
          },
          children: [
            {
              path: 'site-mapper',
              name: ROUTES.ThreeSixtyBaseStationSiteMapper,
              component: ThreeSixtyBaseStationSiteMapper,
              meta: {
                getHeaderTitle: () => ['Base Station', '\u00b7', 'Site Mapper'],
                gtm: '360 Site Mapper',
              },
            },
            {
              path: 'cell-congestion',
              name: ROUTES.ThreeSixtyBaseStationCellCongestion,
              component: ThreeSixtyBaseStationCellCongestion,
              meta: {
                getHeaderTitle: () => ['Base Station', '\u00b7', 'Cell Congestion'],
                gtm: '360 Cell Congestion',
              },
            },
            {
              path: 'overshooting',
              name: ROUTES.ThreeSixtyBaseStationOvershooting,
              component: ThreeSixtyBaseStationOvershooting,
              meta: {
                getHeaderTitle: () => ['Base Station', '\u00b7', 'Overshooting'],
                gtm: '360 Overshooting',
              },
            },
          ],
        },
        {
          path: 'core',
          name: ROUTES.ThreeSixtyCore,
          components: {
            default: RouterDefaultPassThrough,
            header: ThreeSixtyCoreHeader,
          },
          children: [
            {
              path: 'endpoint-ip-speed',
              name: ROUTES.ThreeSixtyCoreEndpointIPSpeed,
              component: ThreeSixtyCoreEndpointIPSpeed,
              meta: {
                getHeaderTitle: () => ['Core', '\u00b7', 'Endpoint IP Speed'],
                gtm: '360 Endpoint IP Speed',
              },
            },
            {
              path: 'endpoint-ip-games-and-voice',
              name: ROUTES.ThreeSixtyCoreEndpointIPGamesAndVoice,
              component: ThreeSixtyCoreEndpointIPGamesAndVoice,
              meta: {
                getHeaderTitle: () => ['Core', '\u00b7', 'Endpoint IP Games and Voice'],
                gtm: '360 Endpoint IP Games and Voice',
              },
            },
            {
              path: 'endpoint-ip-ecq-and-ccq',
              name: ROUTES.ThreeSixtyCoreEndpointIPEcqCcq,
              component: ThreeSixtyCoreEndpointIPEcqAndCcq,
              meta: {
                getHeaderTitle: () => ['Core', '\u00b7', 'Endpoint IP ECQ and CCQ'],
                gtm: '360 Endpoint IP ECQ and CCQ',
              },
            },
            {
              path: 'endpoint-ip-video-abr',
              name: ROUTES.ThreeSixtyCoreEndpointIPVideoABR,
              component: ThreeSixtyCoreEndpointIPVideoABR,
              meta: {
                getHeaderTitle: () => ['Core', '\u00b7', 'Endpoint IP Video ABR'],
                gtm: '360 Endpoint IP Video ABR',
              },
            },
            {
              path: 'endpoint-ip-video-live',
              name: ROUTES.ThreeSixtyCoreEndpointIPVideoLive,
              component: ThreeSixtyCoreEndpointIPVideoLive,
              meta: {
                getHeaderTitle: () => ['Core', '\u00b7', 'Endpoint IP Video Live'],
                gtm: '360 Endpoint IP Video Live',
              },
            },
          ],
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: ROUTES.ThreeSixtyCoverageNetworkCoverage },
        },
      ],
    },
    {
      path: '/',
      name: ROUTES.Landing,
      component: Landing,
      meta: {
        title: 'Opensignal',
      },
    },
    {
      path: '/logout-callback',
      name: ROUTES.LogoutCallback,
      component: LogoutCallback,
    },
  ],
});

router.beforeEach((to, _, next) => {
  document.title = to.matched?.[0]?.meta?.title || 'Opensignal';
  if (to.name === ROUTES.LogoutCallback || to.name === ROUTES.Landing) {
    next();
  } else {
    authGuard(to).then(() => {
      // google tag manager tracking code
      const pageTitle = to.meta.gtm || to.meta.title || to.meta.getHeaderTitle?.().join('>');
      const dataLayer = window.dataLayer || [];
      function gtag(...args) {
        dataLayer.push(args);
      }
      gtag('event', 'page_view', {
        page_title: pageTitle,
        page_path: to.fullPath,
        page_location: window.location.href,
        event_category: to.meta.gtm,
      });

      // actually go to the next route
      next();
    });
  }
});

export default router;
