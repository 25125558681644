<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SidebarLocationSearch from '@/components/SidebarLocationSearch.vue';
import { FieldGroup, Flatpickr, MenuTitle } from '@/components/menu';
import OnxRadioButton from '@/components/onx/OnxRadioButton.vue';
import OnxToggle from '@/components/onx/OnxToggle.vue';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useEndDate from '@/composables/useEndDate';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import { FOCUS_AGGREGATIONS } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import SidebarOperators from '@/components/SidebarOperators.vue';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import GeohashCountIndicator from '@/components/GeohashCountIndicator.vue';
import { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';
import OnxPermissionGuard from '@/components/onx/OnxPermissionGuard.vue';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import useAnalytics from '@/composables/useAnalytics';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

const router = useRouter();
const route = useRoute();
const dashboardName = useCurrentDashboardName();
const { currentEndDate, parsedFirstDateAvailable, parsedLastDateAvailable } = useEndDate();
const { currentCountry } = useLocations();
const { aggregation, metricSubtype, setFilters, showConfidenceIntervals } = useFilters();
const { geohashes } = useGeohashesQueryParam();
const { data: dashboardInfo } = useDashboardInfo(dashboardName.value);
const { track } = useAnalytics();

const sidebarOperatorsAccessor = () => useSelectableNetworkOperators(dashboardName.value);

const onLocationChange = async (location: any) => {
  router.push({
    query: {
      ...route.query,
      geohashes: [],
      location: location.key,
      operators: undefined,
    },
  });
};

const onResetLocation = () => {
  router.push({
    query: {
      ...route.query,
      geohashes: [],
      location: currentCountry.value.key,
    },
  });
};

const onEndDateChange = (rawDate: string) => {
  setFilters({ endDate: rawDate });
};

const computedConfidenceIntervals = computed({
  get: () => showConfidenceIntervals.value,
  set: (value: boolean) => {
    setFilters({ showConfidenceIntervals: value.toString() });
  },
});

const canToggleConfidenceIntervals = computed(() => {
  const isRouteExcluded = [
    ROUTES.FocusRANHealth,
    ROUTES.FocusRANHealthCoverage,
    ROUTES.FocusRANHealthAvailability,
    ROUTES.FocusRANHealthNetworkRollout,
    ROUTES.FocusRANHealthSiteMapper,
  ].includes(router.currentRoute.value.name as string);

  return (
    !isRouteExcluded &&
    !geohashes.value.length &&
    ![MetricSubtypes.Ecq, MetricSubtypes.Ccq].includes(metricSubtype.value as any)
  );
});

const canSelectEndDate = computed(() => {
  return ![
    ROUTES.FocusRANHealthCoverage,
    ROUTES.FocusRANHealthNetworkRollout,
    ROUTES.FocusRANHealthDeployedSpectrum,
    ROUTES.FocusRANHealthSiteMapper,
  ].includes(router.currentRoute.value.name as string);
});

const canSelectAggregations = computed(() => {
  const excludedRoutes = [
    ROUTES.FocusRANHealthCoverage,
    ROUTES.FocusRANHealthNetworkRollout,
    ROUTES.FocusRANHealthDeployedSpectrum,
    ROUTES.FocusRANHealthSiteMapper,
  ];
  const isRouteExcluded = excludedRoutes.includes(router.currentRoute.value.name as string);

  const excludedMetrics = [
    MetricSubtypes.Ecq,
    MetricSubtypes.EcqDownloadThroughput,
    MetricSubtypes.EcqUploadThroughput,
    MetricSubtypes.EcqIcmpLatency,
    MetricSubtypes.EcqJitter,
    MetricSubtypes.EcqPacketLoss,
    MetricSubtypes.EcqLatency,
    MetricSubtypes.EcqDownloadTimeFirstByte,
    MetricSubtypes.Ccq,
    MetricSubtypes.CcqDownloadThroughput,
    MetricSubtypes.CcqUploadThroughput,
    MetricSubtypes.CcqIcmpLatency,
    MetricSubtypes.CcqJitter,
    MetricSubtypes.CcqPacketLoss,
    MetricSubtypes.CcqLatency,
    MetricSubtypes.CcqDownloadTimeFirstByte,
  ];
  const isMetricExcluded = excludedMetrics.includes(metricSubtype.value as any);

  return !geohashes.value.length && !isMetricExcluded && !isRouteExcluded;
});

const computedAggregation = computed({
  get: () => aggregation.value,
  set: (value: string) => {
    setFilters({ aggregation: value });
  },
});

const aggregationOptions = computed(() => {
  const aggregationTypesEnabled = dashboardInfo.value?.data.aggregation_types_enabled ?? [];
  return FOCUS_AGGREGATIONS.filter((agg) => aggregationTypesEnabled.includes(agg.value));
});

const trackConfidenceIntervalChange = (newConfidenceInterval: boolean) => {
  track('show confidence intervals change', { newConfidenceInterval });
};

const trackAggregationChange = (newAggregation: string) => {
  track('aggregation change', { newAggregation });
};

const reliabilitySelected = computed(() => {
  return route.query.metricSubtype === MetricSubtypes.ReliabilitySessionRelability;
});
</script>

<template>
  <MenuTitle label="Parameters" theme="ci" />

  <SidebarLocationSearch :on-location-change="onLocationChange" :on-reset-location="onResetLocation">
    <OnxPermissionGuard v-if="!reliabilitySelected" :dashboard="dashboardName" :topic="OnxPermissionTopics.geohash">
      <GeohashCountIndicator :count="geohashes.length" />
    </OnxPermissionGuard>
  </SidebarLocationSearch>

  <FieldGroup label="End Date">
    <Flatpickr
      :min="parsedFirstDateAvailable"
      :max="parsedLastDateAvailable"
      :date="currentEndDate"
      @selected="onEndDateChange"
      :disabled="!canSelectEndDate"
    />
  </FieldGroup>

  <FieldGroup :tooltip="TOOLTIP_MESSAGES.aggregation" label="Aggregation">
    <OnxRadioButton
      v-for="option in aggregationOptions"
      name="aggregation-radio"
      :key="option.value"
      v-model="computedAggregation"
      :value="option.value"
      :label="option.label"
      :disabled="!canSelectAggregations"
      @update:model-value="trackAggregationChange"
    />
  </FieldGroup>

  <FieldGroup>
    <OnxToggle
      v-model="computedConfidenceIntervals"
      label="Show confidence intervals"
      class="onx-show-confidence-toggle"
      :disabled="!canToggleConfidenceIntervals"
      @update:model-value="trackConfidenceIntervalChange"
    />
  </FieldGroup>

  <SidebarOperators :operators-accessor="sidebarOperatorsAccessor" />
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.Focus {
  & .onx-grid.fluid {
    @include desktop {
      --onx-grid-fluid-item-default-min-width: 500px;
    }
  }

  .PerformanceLayout__content {
    padding: 8px;

    @include laptop {
      padding: 16px;
    }
  }
}

.onx-show-confidence-toggle {
  width: 100%;
  height: 32px;
  color: $color-white;
}
</style>
