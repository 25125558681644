<script setup lang="ts">
import type { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricSubtypeTitles } from '@/focus/constants/titles';
import { ChartTypesEnum } from '@/types/Charts';
import use360ConnectionCategories from '@/360/use360ConnectionCategories';
import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import OnxSectionHeader from '@/components/onx/OnxSectionHeader.vue';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import Routes from '@/constants/routes';

const connectionCategories = use360ConnectionCategories();

const metricsGroups = chartMetricDefinitionsByPage[Routes.ThreeSixtyCoreEndpointIPSpeed] as Record<
  string,
  ChartMetricDefinition[]
>;
const { aggregation } = useFilters();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const { locationId } = useLocations();
</script>

<template>
  <div class="onx-360-core-endpoint-ip-speed three-sixty__charts-container">
    <section
      v-for="metricSubtype in [MetricSubtypes.Download, MetricSubtypes.Upload]"
      :key="metricSubtype"
      class="onx-360-core-endpoint-ip-speed__metric-group"
    >
      <OnxSectionHeader>
        <span>{{ MetricSubtypeTitles[metricSubtype] }}</span>
      </OnxSectionHeader>

      <div class="onx-grid fluid fit">
        <template v-for="connectionCategory in connectionCategories.selectedChartConnectionCategories">
          <template v-for="metric in metricsGroups[metricSubtype]">
            <template
              v-if="metric.chartType === ChartTypesEnum.Table || metric.chartType === ChartTypesEnum.DatasetTable"
            >
              <ChartManager
                :key="`${metric.metricSubtype}_${connectionCategory.categoryValue}`"
                :metric="metric"
                :connection-category="connectionCategory.categoryValue"
                :connection-category-label="connectionCategory.categoryLabel"
                :location="locationId"
                :geohashes="[]"
                :aggregation="aggregation"
                :operators="selectedOperators"
              />
            </template>

            <template
              v-else
              v-for="operator in selectedOperators"
              :key="`${operator.name_mapped}-${metric.metricSubtype}_${connectionCategory.categoryValue}`"
            >
              <ChartManager
                :dashboard="Dashboards.ThreeSixty"
                :metric="metric"
                :connection-category="connectionCategory.categoryValue"
                :connection-category-label="connectionCategory.categoryLabel"
                :location="locationId"
                :geohashes="[]"
                :aggregation="aggregation"
                :operators="selectedOperators"
                :main-operator="operator"
              />
            </template>
          </template>
        </template>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.onx-360-core-endpoint-ip-speed {
  &__stacked {
    padding-top: 16px;
  }

  .onx-table-container {
    margin-bottom: 16px;

    > .onx-headline {
      margin-top: 0;
    }
  }

  &__metric-group {
    margin-bottom: 8px;

    @include tablet() {
      margin-bottom: 16px;
    }
  }
}
</style>
