import { computed, MaybeRef, unref } from 'vue';
import { useRoute } from 'vue-router';

import { ChartTypeGroups, chartTypeGroupLabels, chartTypeGroupingMap } from './constants';

import { type ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import useFilters from '@/composables/useFilters';
import { ChartTypesEnum } from '@/types/Charts';
import useQueryParamMultiSelect from '@/composables/useQueryParamMultiSelect';
import ROUTES from '@/constants/routes';
import useAnalytics from '@/composables/useAnalytics';

const defaultChartTypes = [
  ChartTypeGroups.TREND,
  ChartTypeGroups.DISTRIBUTION,
  ChartTypeGroups.BY_CDN,
  ChartTypeGroups.BY_OPERATOR,
];

const chartTypeSorting = {
  [ChartTypeGroups.TREND]: 0,
  [ChartTypeGroups.DISTRIBUTION]: 1,
  [ChartTypeGroups.BY_CDN]: 2,
  [ChartTypeGroups.BY_OPERATOR]: 3,
  [ChartTypeGroups.SCORE_PERFORMANCE_DRIVER]: 4,
  [ChartTypeGroups.THRESHOLD]: 5,
  [ChartTypeGroups.FAILURE]: 6,
  [ChartTypeGroups.HOURLY]: 7,
} as Record<ChartTypeGroups, number>;

export default (maybeRefMetricDefinitions: MaybeRef<ChartMetricDefinition[] | null | undefined>) => {
  const route = useRoute();
  const { metricSubtype } = useFilters();
  const { track } = useAnalytics();

  const allowedChartTypes = computed<ChartTypeGroups[]>(() => {
    const metricDefinitions = unref(maybeRefMetricDefinitions);

    let availableChartTypes = [...defaultChartTypes];

    if (metricDefinitions) {
      availableChartTypes = [
        ...new Set(
          metricDefinitions.map((def) => chartTypeGroupingMap[def.chartType as keyof typeof chartTypeGroupingMap]),
        ),
      ];
    }

    availableChartTypes.sort((a, b) => chartTypeSorting[a] - chartTypeSorting[b]);

    return availableChartTypes as ChartTypeGroups[];
  });

  const defaultValue = computed<ChartTypeGroups[]>(() => {
    if (route.name === ROUTES.FocusQoEDetails && ['ecq', 'ccq'].includes(metricSubtype.value as string)) {
      return [ChartTypeGroups.THRESHOLD];
    } else if (route.name === ROUTES.FocusQoEDetails && metricSubtype.value === 'videoexperience') {
      return [ChartTypeGroups.TREND, ChartTypeGroups.BY_OPERATOR];
    } else if (route.name === ROUTES.FocusQoEDetails && metricSubtype.value === 'sessionreliability') {
      return [ChartTypeGroups.SCORE_PERFORMANCE_DRIVER, ChartTypeGroups.TREND];
    } else {
      return [ChartTypeGroups.TREND];
    }
  });

  const { onChange, selectedValues } = useQueryParamMultiSelect<ChartTypeGroups>('chartTypes', defaultValue, {
    allowedValues: allowedChartTypes,
  });

  const filterSelectedChartType = (
    chartType: Exclude<ChartTypesEnum, ChartTypesEnum.Table | ChartTypesEnum.CoverageMap | ChartTypesEnum.Gauge>,
  ): boolean => {
    return selectedValues.value.includes(chartTypeGroupingMap[chartType]);
  };

  const selectedChartTypesLabel = computed(() => {
    if (selectedValues.value.length === 0) {
      return 'None';
    } else {
      return selectedValues.value.map((type) => chartTypeGroupLabels[type]).join(', ');
    }
  });

  const onChartTypeChange: typeof onChange = (changed) => {
    return onChange(changed).then(() => {
      track('chart type change', { changed, selectedValues: selectedValues.value });
    });
  };

  return {
    chartTypeLabels: chartTypeGroupLabels,
    chartTypes: allowedChartTypes,
    filterSelectedChartType,
    selectedChartTypesLabel,
    onChartTypeChange,
    selectedChartTypes: selectedValues,
  };
};
