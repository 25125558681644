import { computed, MaybeRef, unref } from 'vue';

import type { Dashboards } from '@/constants/dashboards';

import usePolygons from '@/composables/usePolygons';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import { MetricStructures, MetricStructuresEnum } from '@/types/MetricStructures';
import { getDatumValue } from '@/utils/viewHelpers';

type Options = Omit<Parameters<typeof useGeocodingConfig>[1], 'enabled'> & {
  enableGeocodingConfigQuery?: MaybeRef<boolean>;
};

const usePolygonsWithMetrics = <T extends MetricStructuresEnum>(dashboard: Dashboards, options: Options) => {
  const { countryIso3, enableGeocodingConfigQuery, geocoding, ...geocodingConfigRest } = options;

  const polygonsQuery = usePolygons(dashboard, { geocoding, countryIso3 });
  const { isDbScalingUp, query: geocodingConfigQuery } = useGeocodingConfig<T>(dashboard, {
    geocoding,
    countryIso3,
    ...geocodingConfigRest,
    operatorInfo: true,
    enabled: computed(() => {
      if (enableGeocodingConfigQuery) {
        return unref(enableGeocodingConfigQuery) && polygonsQuery.isSuccess.value;
      }

      return polygonsQuery.isSuccess.value;
    }),
  });

  const polygonsWithMetrics = computed(() => {
    if (!geocodingConfigQuery.data.value || !polygonsQuery.data.value) {
      return null;
    }

    const geocodingConfigData = geocodingConfigQuery.data.value.data;
    const polygonData = polygonsQuery.data.value.data;

    const geocodingConfigByLocation = geocodingConfigData.results.reduce<{
      [key: number]: MetricStructures[T][];
    }>((acc, geocodingConfig) => {
      acc[geocodingConfig.location] = acc[geocodingConfig.location] || [];
      acc[geocodingConfig.location].push(geocodingConfig);

      return acc;
    }, {});

    const result = polygonData.features.map((polygon) => {
      if (!polygon.id) {
        return polygon;
      }

      const polygonId = typeof polygon.id === 'number' ? polygon.id : parseInt(polygon.id, 10);
      const polygonMetrics = geocodingConfigByLocation[polygonId] || [];

      const featureData = polygonMetrics.map((item) => {
        const operator = geocodingConfigQuery.data.value?.data.operators?.[item.canonical_network_id];

        return {
          ...item,
          value: getDatumValue(item),
          operator,
          operatorInitial: geocodingConfigQuery.data.value?.data.operators?.[item.canonical_network_id]?.name_mapped[0],
        };
      });

      return {
        ...polygon,
        item: featureData,
        properties: {
          ...polygon.properties,
          data: featureData,
        },
      };
    });

    return {
      type: 'FeatureCollection' as const,
      bbox: polygonData.bbox,
      features: result,
    };
  });

  const polygonQueriesLoading = computed(() => {
    return polygonsQuery.isLoading.value || geocodingConfigQuery.isLoading.value;
  });

  return {
    polygonsWithMetrics,
    polygonsQuery,
    geocodingConfigQuery,
    polygonQueriesLoading,
    isDbScalingUp,
  };
};

export default usePolygonsWithMetrics;
export type PolygonsWithMetricsType = ReturnType<typeof usePolygonsWithMetrics>['polygonsWithMetrics'];
