<script setup lang="ts">
import { computed } from 'vue';
import OnxButton from '../OnxButton.vue';
import CloseIcon from '../icons/CloseIcon.vue';
import ChevronDownIcon from '../icons/ChevronDownIcon.vue';
import { SpotlightPulse } from './useSpotlightPulse';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';

const props = defineProps<{
  allPulseItems: SpotlightPulse[];
  selectedMetricSubtype?: string;
  onChange: (metricSubtype: string) => void;
  onClear: () => void;
}>();

const filteredMetricSubtypes = computed<string[]>(() => {
  return [...new Set(props.allPulseItems.map((item) => item.metric_subtype).filter(Boolean))] as string[];
});

const items = computed(() => {
  const _selectedMetricSubtype = props.selectedMetricSubtype;
  return filteredMetricSubtypes.value.map((metricSubtype) => ({
    keyAccessor: metricSubtype,
    label: metricSubtype,
    value: metricSubtype,
    checked: _selectedMetricSubtype !== undefined && _selectedMetricSubtype === metricSubtype,
  }));
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Metric Subtype:"
    :selectedLabel="selectedMetricSubtype"
    :items="items"
    @list-item-click="onChange"
  >
    <OnxButton variant="tertiary">
      <span class="onx-radio-button-list-dropdown-label">Metric Subtype:</span>
      <span v-if="selectedMetricSubtype">{{ selectedMetricSubtype }}</span>
      <CloseIcon v-if="selectedMetricSubtype" @click.stop="onClear" />
      <ChevronDownIcon />
    </OnxButton>
  </OnxRadioButtonListDropdown>
</template>
