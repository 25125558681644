<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { computed } from 'vue';
import ThreeSixtyHeader from '@/360/ThreeSixtyHeader.vue';
import Routes from '@/constants/routes';
import { OnxTab, OnxTabs, OnxTabsContext } from '@/components/onx/tabs';
import { DashboardTopics } from '@/constants/dashboards';
import useDashboardTopicCountryInfo from '@/composables/useDashboardTopicCountryInfo';
import useLocations from '@/composables/useLocations';
import use360TabAvailability from '@/360/use360TabAvailability';

const route = useRoute();
const router = useRouter();

// Tells the user when the dashboard was created.
const { countryIso3 } = useLocations();
const { data: dashboardTopicCountryInfoResponse } = useDashboardTopicCountryInfo(
  DashboardTopics.COVERAGE,
  countryIso3.value,
);
const latestDataDate = computed<string | null>(() => {
  return dashboardTopicCountryInfoResponse?.value?.data?.creation_date || null;
});

const { firstAvailableSubtabForCoverage, tabAvailability } = use360TabAvailability();

onMounted(() => {
  if (route.name === Routes.ThreeSixtyCoverage) {
    router.push({
      name: firstAvailableSubtabForCoverage.value,
    });
  }
});
</script>

<template>
  <ThreeSixtyHeader>
    <template #header-controls v-if="latestDataDate">
      <div class="network-started-note">
        <span class="network-started-note__label">Latest data:</span>
        <span class="network-started-note__value">{{ latestDataDate }}</span>
      </div>
    </template>
    <template #secondary-tabs="{ onTabChange }">
      <OnxTabsContext :selected-tab="route.matched?.[2]?.name">
        <OnxTabs @change="onTabChange">
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoverageNetworkCoverage]"
            :id="Routes.ThreeSixtyCoverageNetworkCoverage"
          >
            Network Coverage
          </OnxTab>
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoverageAvailability]"
            :id="Routes.ThreeSixtyCoverageAvailability"
          >
            Availability (4G Devices)
          </OnxTab>
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoverageAvailability5G]"
            :id="Routes.ThreeSixtyCoverageAvailability5G"
          >
            Availability (5G Devices)
          </OnxTab>
        </OnxTabs>
      </OnxTabsContext>
    </template>
  </ThreeSixtyHeader>
</template>

<style lang="scss">
.network-started-note {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .network-started-note__label {
    font-weight: bold;
  }
}
</style>
