import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { RankedSimpleMetric } from '@/types/MetricStructures';
import type { Operator } from '@/types/Operator';

import { type CompareToValues, METRIC_STRUCTURE_TO_VALUE_FIELD_MAP } from '@/constants/constants';

import { isAwardMetric } from '@/utils/metrics';
import { fixHexColor } from '@/utils/helpers';
import { getDatumValue } from '@/utils/viewHelpers';
import { metricToFullTitle } from '@/utils/titles';
import { getScoreChanges } from '@/utils/score';

type CreateMetricBoxDataArgs = {
  data: RankedSimpleMetric[];
  selectedMetric: MetricDescriptor;
  operators: Operator[] | Record<string, Operator>;
  homeNetwork: Operator;
  compareTo: CompareToValues;
  title?: string;
};

const createMetricBoxData = (args: CreateMetricBoxDataArgs) => {
  const { compareTo, data, homeNetwork, operators, selectedMetric, title } = args;
  const homeOperatorRanksSource = data.find((o) => o.canonical_network_id === homeNetwork.canonical_network_id);

  return {
    rawMetric: selectedMetric,
    metric: selectedMetric.key,
    biggerBetter: selectedMetric.bigger_is_better,
    isAwardMetric: isAwardMetric(selectedMetric),
    title: title || metricToFullTitle(selectedMetric),
    byConnectionDisabled: selectedMetric.category === 'ecq' || selectedMetric.category === 'ccq',
    data: data.map((datum) => {
      let operator;

      if (Array.isArray(operators)) {
        operator = operators.find((o) => o.canonical_network_id === datum.canonical_network_id);
      } else {
        operator = operators[datum.canonical_network_id];
      }

      if (!operator) {
        return null;
      }

      const valueProperty =
        METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[
          selectedMetric.structure as keyof typeof METRIC_STRUCTURE_TO_VALUE_FIELD_MAP
        ];
      return {
        value: getDatumValue(datum),
        lci: datum.lci,
        uci: datum.uci,
        operatorColor: fixHexColor(operator.hex_color),
        operatorInitial: operator.name_mapped[0],
        operatorName: operator.name_mapped,
        operatorIsMvno: operator.is_mvno,
        canonicalNetworkId: operator.canonical_network_id,
        // at the time of this writing, MVNOs are not ranked and their rank is set to 0
        // ranks normally start at 1
        // MVNOs will be ranked in time, so 0 will change to >= 1
        // that's why this does not check is_mvno, but rather only for the placeholder 0
        rank: datum.rank === 0 ? null : datum.rank,
        previous: datum.previous?.[compareTo]?.mean,
        scoreChange: getScoreChanges(
          datum,
          homeOperatorRanksSource,
          valueProperty,
          compareTo,
          datum.canonical_network_id === homeNetwork.canonical_network_id,
        ),
        ...(datum.comparison?.[compareTo]
          ? {
              comparisonRank: datum.comparison[compareTo]?.rank,
              comparisonMean: datum.comparison[compareTo]?.[valueProperty as keyof RankedSimpleMetric],
            }
          : {}),
      };
    }),
  };
};

export default createMetricBoxData;
