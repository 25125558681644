import { computed, unref, watchEffect, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

type Options<T extends string> = {
  allowEmpty?: boolean;
  allowedValues?: Ref<T[]> | undefined;
};

const useQueryParamMultiSelect = <T extends string>(
  queryParam: string,
  defaultValue: Ref<T> | Ref<T[]>,
  options: Options<T> = {},
) => {
  const { allowEmpty = false, allowedValues } = options;

  const route = useRoute();
  const router = useRouter();

  const computedDefaultValue = computed(() => {
    return Array.isArray(defaultValue.value) ? defaultValue.value : (defaultValue.value.split(',') as T[]);
  });

  const selectedValues = computed(() => {
    const queryParamValue = route.query[queryParam] || computedDefaultValue.value;

    const values = Array.isArray(queryParamValue) ? queryParamValue : queryParamValue.split(',');
    const allowed = unref(allowedValues) || ([] as T[]);

    if (allowed.length === 0) {
      return values as T[];
    }

    return values.filter((value) => allowed.includes(value as T)) as T[];
  });

  const onChange = (changed: T) => {
    const newValues = new Set<T>(selectedValues.value);
    if (!newValues.has(changed)) {
      // if we need to add a new item, add it
      newValues.add(changed);
    } else if (newValues.size > 1 || allowEmpty) {
      // otherwise, remove it if there's at least another remaining item
      newValues.delete(changed);
    }

    return router.push({
      query: {
        ...route.query,
        [queryParam]: [...newValues].join(',') || [],
      },
    });
  };

  const reset = () => {
    return router.replace({
      query: {
        ...route.query,
        [queryParam]: computedDefaultValue.value.join(',') || [],
      },
    });
  };

  // For example, if chartTypes=threshold and one navigates to QoE Details games,
  // then selectedValues would be [], because threshold is not an allowed value.
  // `reset` is called to go back to the default value
  watchEffect(() => {
    if (selectedValues.value.length === 0 && defaultValue.value.length > 0) {
      reset();
    }
  });

  return {
    onChange,
    selectedValues,
    reset,
  };
};

export default useQueryParamMultiSelect;
