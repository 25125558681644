import {
  deployedSpectrumDetailsColumns,
  deployedSpectrumTableTransformer,
} from './deployedSpectrumDetailsColumnDefinitions';
import { ChartMetricDefinition, InitialSortDirection } from '@/chart-metric-definitions/MetricDefinition';
import { ChartTypesEnum } from '@/types/Charts';

export const deployedSpectrumChartMetricDefinitions: ChartMetricDefinition[] = [
  {
    dataset: 'focus_deployed_spectrum_table',
    chartType: ChartTypesEnum.DatasetTable,
    chartAttrs: {
      chartTitlePlaceholders: 'Deployed Spectrum Details',
    },
    chartContainerClass: 'onx-grid-item__full-width',
    columns: deployedSpectrumDetailsColumns,
    transformData: deployedSpectrumTableTransformer,
    initialSortColumnKey: 'location_city',
    initialSortDirection: InitialSortDirection.Asc,
  },
];
