import { computed, reactive, watch } from 'vue';
import type { Operator } from '@/types/Operator';

import useLocations from '@/composables/useLocations';
import useHomeNetwork from '@/composables/useHomeNetwork';
import { Dashboards } from '@/constants/dashboards';
import { LOCAL_STORAGE_KEYS } from '@/constants/constants';

const _operatorsByLocation = reactive<Record<string, Operator[]>>({});

const operatorVisibilityFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.OPERATOR_VISIBILITY);
const _operatorVisibility = reactive<Record<string, boolean>>(
  operatorVisibilityFromLocalStorage !== null ? JSON.parse(operatorVisibilityFromLocalStorage) : {},
);

watch(_operatorVisibility, (newVisibility) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.OPERATOR_VISIBILITY, JSON.stringify(newVisibility));
});

const useSpotlightNetworkOperators = () => {
  const { locationId } = useLocations();
  const homeNetwork = useHomeNetwork(Dashboards.Spotlight, locationId);

  const networkOperators = computed(() => {
    if (!locationId.value) {
      return [];
    }

    const operatorsByLocation = _operatorsByLocation[locationId.value] || [];

    const allNetworksWithSelected = operatorsByLocation.map((operator) => {
      const selected = _operatorVisibility[operator.canonical_network_id] ?? !operator.is_mvno;

      return {
        ...operator,
        selected,
      };
    });

    if (homeNetwork.value) {
      const homeNetworkVisibility = _operatorVisibility[homeNetwork.value.canonical_network_id] !== false;

      const homeNetworkWithSelected = { ...homeNetwork.value, selected: homeNetworkVisibility };

      // put the home network first, and mark if it's selected
      return [
        homeNetworkWithSelected,
        // all the other networks
        ...allNetworksWithSelected.filter((operator) => {
          return operator.canonical_network_id !== homeNetwork.value?.canonical_network_id;
        }),
      ];
    } else {
      return allNetworksWithSelected;
    }
  });

  const selectedNetworkOperators = computed(() => {
    return networkOperators.value.filter((operator) => operator.selected);
  });

  const setOperators = (newOperators: Operator[]) => {
    if (!locationId.value) {
      return;
    }

    if (_operatorsByLocation[locationId.value]) {
      const currentOperatorIds = _operatorsByLocation[locationId.value].map(
        (operator) => operator.canonical_network_id,
      );

      _operatorsByLocation[locationId.value] = [
        ..._operatorsByLocation[locationId.value],
        ...newOperators.filter((operator) => !currentOperatorIds.includes(operator.canonical_network_id)),
      ];
    } else {
      _operatorsByLocation[locationId.value] = newOperators;
    }

    /**
     * Should be run when first loading a spotlight page, like overview or by geography.
     * It sets the initial visibility of operators based on the home operator and the operators
     * loaded for the page. MVNOs that don't yet have visibility set will be hidden by default.
     * The home operator will be visible by default;
     */
    for (const operator of Object.values(newOperators)) {
      if (operator.is_mvno && !_operatorVisibility.hasOwnProperty(operator.canonical_network_id)) {
        _operatorVisibility[operator.canonical_network_id] = false;
      } else if (!_operatorVisibility.hasOwnProperty(operator.canonical_network_id)) {
        _operatorVisibility[operator.canonical_network_id] = true;
      }
    }
  };

  const operators = computed(() => {
    if (!locationId.value) {
      return [];
    }

    return _operatorsByLocation[locationId.value] || [];
  });

  const isOperatorVisible = (operator: number | string | Operator) => {
    // operator is either the ID or the operator object. Try to use the object, I guess.
    let canonicalNetworkID;
    if (typeof operator === 'number') {
      canonicalNetworkID = operator;
    } else if (typeof operator === 'string') {
      canonicalNetworkID = parseInt(operator, 10);
    } else {
      canonicalNetworkID = operator.canonical_network_id;
    }

    return _operatorVisibility[canonicalNetworkID] ?? true;
  };

  const toggleOperatorVisibility = (operator: number | string) => {
    _operatorVisibility[operator] = !_operatorVisibility[operator];
  };

  const setOperatorsVisibility = (operatorIds: number[] | string[], visibility: boolean) => {
    for (const operatorId of operatorIds) {
      _operatorVisibility[operatorId] = visibility;
    }
  };

  return {
    networkOperators,
    selectedNetworkOperators,
    operators,
    setOperators,
    isOperatorVisible,
    toggleOperatorVisibility,
    setOperatorsVisibility,
  };
};

export default useSpotlightNetworkOperators;
